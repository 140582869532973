import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';



class GoogleMap extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { restaurant_details_data } = this.props
        const mapStyles = {
            width: '600',
            height: '450',
        };
        return (
            <>
            {restaurant_details_data && restaurant_details_data.responseStatus.STATUS == "SUCCESS" &&
            <Map
            google={this.props.google}
            zoom={8}
            style={mapStyles}
            initialCenter={{ lat: restaurant_details_data.responseData.restaurantLat, lng: restaurant_details_data.responseData.restaurantLong}}
            // initialCenter = {{lat:22.612381, lng:88.407955}}
            >
                <Marker position={{ lat: restaurant_details_data.responseData.restaurantLat, lng: restaurant_details_data.responseData.restaurantLong}} />
            </Map>
            }
            </>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDjh3KIBDNC4juNrhzTbMvvInsB6Uw8jKA'
  })(GoogleMap);