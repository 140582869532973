import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withNamespaces, Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from 'yup';
import  OrderCartList  from "../OrderCartList/OrderCartList"
import FocusError from "../../Utility/FocusError" 
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { phoneValidator, showNotification, dateFormatToFranceTimeZone, dateFormatChange } from '../../../Helper/Helper';
import { collectAmount, deliverAmount, publishableKey } from '../../../config/Config';
import {
    checkout, set_order_state
} from "../../../redux/reducers/order";
import {
    get_customer_details
} from "../../../redux/reducers/customer";
import { getSessionOrAuthorizedToken } from '../../../Helper/Helper'
import StripeCheckout from 'react-stripe-checkout';
import { changeLang,page } from '../../../redux/reducers/setting';
import Loader from 'react-loader-spinner'


const CustomInput = React.forwardRef((props, ref) => {
    return (
        <input
            onClick={props.onClick}
            value={props.value}
            type="text"
            readOnly={true}
        />
    )
})



const DatePickerField = React.forwardRef((props, ref) => {
    return (
        <DatePicker {...props}
            selected={props.selected}
            onChange={val => {
                props.onChange(props.name, val);
            }}
        />
    )
});

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
            minDate : this.getFranceDate(),
            shipping_address_panel : "show",
            billing_address_panel : "",
            payment_mdoe_panel : "",
            payment_panel : "",
            showDeliveryCharge : collectAmount,
            finalFormValues : ''
		};
        this.resetFormData = '';
        this.showOnce = true;
    }
    collapse = (div) => {
        let action = this.state[div];
        this.setState({
            [div] : action ? '' : 'show'
        })
    }
    dateToTs = (date)  => {
        // console.log(date)
        // let dateT = (date.getTime()/1000);
        // console.log(dateT)
        var dateT = dateFormatChange(date)
        // console.log(dateT)
        return dateT
    }
    changeDeliveryStatus = (status) => {
        if(status == "DELIVER"){
            this.setState({
                showDeliveryCharge : deliverAmount
            })
        }else{
            this.setState({
                showDeliveryCharge : collectAmount
            })
        }
    }
    getFranceDate = () => {
        var options = {
            timeZone: 'Europe/Paris',
            hour12: true,
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric',
        },
            formatter = new Intl.DateTimeFormat(['en-US'], options)
            var d2 = new Date(formatter.format(new Date()));
        return(d2)
        
        // return new Date()
    }
    addTimetoFranceDate = () => {
        var d2 =this.getFranceDate();
        // d2.setMinutes ( d2.getMinutes() + 30 );
        let d= d2.getMinutes();
        let addTime = parseInt( d/ 30) * 30;
        if(addTime == 0){
            addTime = addTime + 30;
        }else if(addTime == 30){
            addTime = 0;
            d2.setHours(d2.getHours()+1);
        }
        d2.setMinutes ( addTime );
        return d2;
    }
    checkDateDiff = (dateChose) => {
        let currentDate = this.getFranceDate();
        const date1 = new Date(currentDate);
        const date2 = new Date(dateChose);
        var seconds = (date2.getTime() - date1.getTime()) / 1000;
        if(seconds > 0){
            return true
        }else{
            return false
        }
    }
    onToken = (token) => {
        const {checkout} = this.props;
        let values = this.state.finalFormValues;
        values.token = token.id;
        values.token_card_name = token.card.name;
        checkout(values);
    }
    render() { 
        const { t, lang, customerDetail, checkout_response, responseDismiss, checkout, history, loadingCheckout , loginDetails, customerDetails, totalPrice,changeLang} = this.props;
        if(checkout_response && checkout_response.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),checkout_response.responseStatus.MESSAGE,'success');
            responseDismiss();
            let data = getSessionOrAuthorizedToken(loginDetails);
            customerDetails(data)
            history.push(`/thank-you`);
        }else if(checkout_response && checkout_response.responseStatus.STATUS == "FAILED"){
            let error_msg = checkout_response.responseStatus.MESSAGE.replace(",", ", ");
            showNotification(t("error"),error_msg,'danger');
            responseDismiss();
        }
		
        if(lang == null){
            changeLang("fr")
        }
        if(lang == "fr"){
            registerLocale("fr", fr);
        }
		
		console.log(lang,this.state.lang)
        var initialValues = {
            shipping_first_name: customerDetail ? customerDetail.responseData.shipping_first_name : '',
            shipping_last_name : customerDetail ? customerDetail.responseData.shipping_last_name : '',
            shipping_phone : customerDetail ? customerDetail.responseData.shipping_phone : '',
            shipping_postal_code : customerDetail ? customerDetail.responseData.shipping_postal_code : '',
            shipping_country : customerDetail ? customerDetail.responseData.shipping_country : '',
            shipping_city : customerDetail ? customerDetail.responseData.shipping_city : '',
            shipping_address : customerDetail ? customerDetail.responseData.shipping_address : '',
            billing_first_name : customerDetail ? customerDetail.responseData.billing_first_name : '',
            billing_last_name : customerDetail ? customerDetail.responseData.billing_last_name : '',
            billing_phone : customerDetail ? customerDetail.responseData.billing_phone : '',
            billing_postal_code : customerDetail ? customerDetail.responseData.billing_postal_code : '',
            billing_country : customerDetail ? customerDetail.responseData.billing_country : '',
            billing_city : customerDetail ? customerDetail.responseData.billing_city : '',
            billing_address : customerDetail ? customerDetail.responseData.billing_address : '',
            delivery_status : 'COLLECT',
            delivery_date_dp : this.addTimetoFranceDate(),
            remarks : '',
            payment : 'COD',
            check : '',
            deliveryCharge : deliverAmount
        };
		if(lang != this.state.lang){
          this.setState({
              lang : lang
          },()=>{
			  this.resetFormData()
          })
        }
        const validationSchema = Yup.object().shape({
            shipping_first_name: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t('required')),
            shipping_last_name: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t("required")),
            shipping_phone: Yup.string()
                .max(10, t("phone_must_be_10_chars"))
                .matches(phoneValidator, t("invalid_phone"))
                .required(t("required")),
            shipping_postal_code: Yup.string()
                .max(10, t("must_be_10_chars"))
                .required(t('required')),
            shipping_country: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t("required")),
            shipping_city: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t("required")),
            shipping_address: Yup.string()
                .max(100, t("must_be_100_chars"))
                .required(t('required')),
            billing_first_name: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t("required")),
            billing_last_name: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t("required")),
            billing_phone: Yup.string()
                .max(10, t("phone_must_be_10_chars"))
                .matches(phoneValidator, t("invalid_phone"))
                .required(t('required')),
            billing_postal_code: Yup.string()
                .max(10, t("must_be_10_chars"))
                .required(t("required")),
            billing_country: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t("required")),
            billing_city: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t("required")),
            billing_address: Yup.string()
                .max(100, t("must_be_100_chars"))
                .required(t("required")),
            delivery_status: Yup.string()
                .required(t('required')),
            delivery_date_dp: Yup.string()
                .required(t("required")),
            check: Yup.string()
                .required(t("required")),
        });
        return ( 
            <Formik
            // enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, form) => {
                let result = this.checkDateDiff(values.delivery_date_dp)
                if(result){
                    values.delivery_date = this.dateToTs(values.delivery_date_dp)
                    if(!loadingCheckout){
                        if(values.payment == "STRIPE"){
                            if(this.showOnce){
                                this.showOnce = false;
                                setTimeout(
                                    function() {
                                        this.showOnce = true
                                    }
                                    .bind(this),
                                    1000
                                );
                                this.setState({
                                    finalFormValues : values
                                })
                                this.inputElement.click();
                                // console.log(values)
                            }
                        }else{
                            checkout(values);
                        }
                        
                        // console.log(values)
                        
                    }
                }else{
                    if(this.showOnce){
                        this.showOnce = false;
                        setTimeout(
                            function() {
                                this.showOnce = true
                            }
                            .bind(this),
                            1000
                        );
                        showNotification(t("error"),t("delivery_date_time_chosen_has_passed_away"),'danger');
                    }
                    
                }
                
                
            }}>

            {formikProps => {
                this.resetFormData = formikProps.resetForm
              const {
                values,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              } = formikProps;
        return ( 
            <form onSubmit={handleSubmit}>
                <Effect
                    formik={formikProps}
                    onSubmissionError={() => {
                        this.setState({
                            shipping_address_panel : "show",
                            billing_address_panel : "show",
                            payment_mdoe_panel : "show",
                            payment_panel : "show"
                        })
                    }}
                />
                <div className="row"> 
                    <div className="col-lg-8 checkout_pos_wrapp checkout_pdesign ">
                        <div className="">
                            <div className="accordion" id="myAccordion">
                                <div className="card shadow-box product_cart_info-tab">
                                    <a className="product_cart_info_heading" data-toggle="collapse" onClick={() => this.collapse('shipping_address_panel')} role="button" aria-expanded="true">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                    <i className="fa fa-address-book"></i>{t("shipping_address")}
                                            </h2>
                                        </div>
                                    </a>

                                    <div className={`collapse product_cart_info_description ${this.state.shipping_address_panel}`} id="shipping_address_panel">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" 
                                                        className="form-control btm-outline" 
                                                        name="shipping_first_name" 
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("first_name")}
                                                        value={values.shipping_first_name} />
                                                        {errors.shipping_first_name ? (
                                                            <div className="error-msg">{errors.shipping_first_name}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline" 
                                                        name="shipping_last_name" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("last_name")}
                                                        value={values.shipping_last_name} />
                                                        {errors.shipping_last_name ? (
                                                            <div className="error-msg">{errors.shipping_last_name}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline" 
                                                        name="shipping_phone" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("phone")}
                                                        value={values.shipping_phone} />
                                                        {errors.shipping_phone ? (
                                                            <div className="error-msg">{errors.shipping_phone}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline" 
                                                        name="shipping_postal_code" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("postal_code")}
                                                        value={values.shipping_postal_code} />
                                                        {errors.shipping_postal_code ? (
                                                            <div className="error-msg">{errors.shipping_postal_code}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline" 
                                                        name="shipping_country" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("country")}
                                                        value={values.shipping_country} />
                                                        {errors.shipping_country ? (
                                                            <div className="error-msg">{errors.shipping_country}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline"  
                                                        name="shipping_city" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("city")}
                                                        value={values.shipping_city} />
                                                        {errors.shipping_city ? (
                                                            <div className="error-msg">{errors.shipping_city}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <textarea className="form-control btm-outline" cols="1" rows="5" 
                                                        name="shipping_address" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("address")}
                                                        value={values.shipping_address} ></textarea>
                                                        {errors.shipping_address ? (
                                                            <div className="error-msg">{errors.shipping_address}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card shadow-box product_cart_info-tab">
                                    <a className="product_cart_info_heading" data-toggle="collapse" onClick={() => this.collapse('billing_address_panel')} role="button" aria-expanded="false">
                                        <div className="card-header" id="headingtwo">
                                            <h2 className="mb-0">
                                                    <i className="fa fa-address-book"></i>{t("billing_address")}
                                            </h2>
                                        </div>
                                    </a>
                                    <div className={`collapse product_cart_info_description ${this.state.billing_address_panel}`} id="billing_address_panel">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline" 
                                                        name="billing_first_name" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("first_name")}
                                                        value={values.billing_first_name} />
                                                        {errors.billing_first_name ? (
                                                            <div className="error-msg">{errors.billing_first_name}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline" 
                                                        name="billing_last_name" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("last_name")}
                                                        value={values.billing_last_name} />
                                                        {errors.billing_last_name ? (
                                                            <div className="error-msg">{errors.billing_last_name}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline" 
                                                        name="billing_phone" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("phone")}
                                                        value={values.billing_phone} />
                                                        {errors.billing_phone ? (
                                                            <div className="error-msg">{errors.billing_phone}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline"   
                                                        name="billing_postal_code" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("postal_code")}
                                                        value={values.billing_postal_code} />
                                                        {errors.billing_postal_code ? (
                                                            <div className="error-msg">{errors.billing_postal_code}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline"   
                                                        name="billing_country" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("country")}
                                                        value={values.billing_country} />
                                                        {errors.billing_country ? (
                                                            <div className="error-msg">{errors.billing_country}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control btm-outline"   
                                                        name="billing_city" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("city")}
                                                        value={values.billing_city} />
                                                        {errors.billing_city ? (
                                                            <div className="error-msg">{errors.billing_city}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <textarea className="form-control btm-outline" cols="1" rows="5" name="billing_address" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("address")}
                                                        value={values.billing_address} ></textarea>
                                                        {errors.billing_address ? (
                                                            <div className="error-msg">{errors.billing_address}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card shadow-box product_cart_info-tab">
                                    <a className="product_cart_info_heading" data-toggle="collapse" onClick={() => this.collapse('payment_mdoe_panel')} role="button" aria-expanded="false">
                                        <div className="card-header" id="headingthree">
                                            <h2 className="mb-0">
                                                <i className="fa fa-car"></i> {t("delivery_method")}
                                            </h2>
                                        </div>
                                    </a>
                                    <div className={`collapse product_cart_info_description ${this.state.payment_mdoe_panel}`} id="payment_mdoe_panel">
                                        <div className="card-body">
                                            <div className="row checkout_delivery_gapbot">
                                                <div className="col-lg-7">
                                                    <input type="radio" name="delivery_status" 
                                                    checked={values.delivery_status === 'COLLECT'}
                                                    onChange={handleChange}
                                                    onClick = {() => this.changeDeliveryStatus("COLLECT")}
                                                    value="COLLECT" />
                                                    <img src={window.location.origin+"/assets/images/bag-final.png"} alt="" />
                                                    {t("click_and_collect")}
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="checkout_delivery_textdelvergap">{collectAmount.toFixed(2)} € TTC</div>
                                                </div>
                                            </div>
                                            <div className="row checkout_delivery_gapbot">
                                                <div className="col-lg-7">
                                                    <input type="radio" name="delivery_status" 
                                                    checked={values.delivery_status === 'DELIVER'}
                                                    onChange={handleChange}
                                                    onClick = {() => this.changeDeliveryStatus("DELIVER")}
                                                    value="DELIVER" />
                                                    <img src={window.location.origin+"/assets/images/dlvr.png"} alt="" />
                                                    {t("home_delivery_in_france")}
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="checkout_delivery_textdelvergap">{deliverAmount.toFixed(2)} € TTC</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group btn_datepick">
                                                        {lang == "fr" && <DatePickerField
                                                        showTimeSelect
                                                        dateFormat="yyyy/MM/dd h:mm aa"
                                                        minDate={this.state.minDate}
                                                        name="delivery_date_dp" 
                                                        customInput={<CustomInput />}
                                                        locale="fr"
                                                        placeholder={t("date_and_time_of_delivery")}
                                                        onChange={setFieldValue}
                                                        selected={values.delivery_date_dp} 
                                                        className="form-control"
                                                        popperClassName="some-custom-class btm-outline"
                                                        popperPlacement="top-end"
                                                        popperModifiers={{
                                                            offset: {
                                                            enabled: true,
                                                            offset: "5px, 10px"
                                                            },
                                                            preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false,
                                                            boundariesElement: "viewport"
                                                            }
                                                        }}/>}

                                                        {lang == "en" && <DatePickerField
                                                        showTimeSelect
                                                        dateFormat="yyyy/MM/dd h:mm aa"
                                                        minDate={this.state.minDate}
                                                        name="delivery_date_dp" 
                                                        customInput={<CustomInput />}
                                                        placeholder={t("date_and_time_of_delivery")}
                                                        onChange={setFieldValue}
                                                        selected={values.delivery_date_dp} className="form-control btm-outline"
                                                        popperClassName="some-custom-class btm-outline"
                                                        popperPlacement="top-end"
                                                        popperModifiers={{
                                                            offset: {
                                                            enabled: true,
                                                            offset: "5px, 10px"
                                                            },
                                                            preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false,
                                                            boundariesElement: "viewport"
                                                            }
                                                        }}/>}
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <textarea className="form-control btm-outline" cols="1" rows="5" 
                                                        name="remarks" onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder={t("comment_on_delivery")}
                                                        value={values.remarks} ></textarea>
                                                        {errors.remarks ? (
                                                            <div className="error-msg">{errors.remarks}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card shadow-box product_cart_info-tab">
                                    <a className="product_cart_info_heading" data-toggle="collapse" onClick={() => this.collapse('payment_panel')} role="button" aria-expanded="false">
                                        <div className="card-header" id="headingfour">
                                            <h2 className="mb-0">
                                                    <i className="fa fa-credit-card"></i>Payment 
                                            </h2>
                                        </div>
                                    </a>
                                    <div className={`collapse product_cart_info_description ${this.state.payment_panel}`} id="payment_panel">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12 checkout_delivery_gapbot">
                                                    <div className="form-group">
                                                        <input type="radio" name="payment" 
                                                        checked={values.payment === 'STRIPE'}
                                                        onChange={handleChange}
                                                        value="STRIPE" />{t("payment_by_credit_card")}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 checkout_delivery_gapbot">
                                                    <div className="form-group">
                                                        <input type="radio" name="payment" 
                                                        checked={values.payment === 'COD'}
                                                        onChange={handleChange}
                                                        value="COD" />{t("cash_on_delivery")}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 checkout_delivery_gapbot">
                                                    <div className="form-group">
                                                        <label className="custom_checkbox">
														<input type="checkbox" name="check"
                                                        onChange={handleChange}
														checked={values.check === true}
                                                        onBlur={handleBlur}
                                                        value={values.check}/><span className="chk_icon"></span>
                                                            <Trans i18nKey="delivery_terms_and_condition">
                                                            J'ai lu <a >les conditions générales</a> de vente et j'y adhère sans réserve.
                                                            </Trans>
                                                        </label>
                                                        {errors.check ? (
                                                            <div className="error-msg">{errors.check}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                

                                <div className="product_cart_cta_area">
								
                                    {loadingCheckout ? 
                                    <><button className="btn btn-solid col-md-3 checkout_gapbtn disable-link" disabled type="button">{t("order")}</button><Loader className="checkout-loader" type="Oval" color="#cccccc" height={40} width={40} /></> :
                                    <button className="btn btn-solid col-md-3 checkout_gapbtn" type="submit" onClick={formikProps.submitForm}>{t("order")}</button>}
									
                                    {console.log(totalPrice,"price")}
                                    <StripeCheckout
                                    locale={lang}
                                    stripeKey={publishableKey}
                                    token={this.onToken}
                                    amount={totalPrice * 100} // cents
                                    currency="EUR"
                                    >
                                    <button style={{display:"none"}} ref={input => this.inputElement = input}>{t("order")}</button>
                                    </StripeCheckout>
                                </div>                
                            </div>
                        </div>
                    </div>
                    <OrderCartList deliveryCharge={this.state.showDeliveryCharge} />
                    <FocusError />
                </div>
            </form>

         );
        }}
        </Formik>
      );
    }
}

function Effect(props) {
    const effect = () => {
      if (props.formik.submitCount > 0 && !props.formik.isValid) {
        props.onSubmissionError();
      }
    };
    React.useEffect(effect, [props.formik.submitCount]);
    return null;
  }

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        totalPrice: state.setting.price,
        checkout_response: state.order.checkout_response,
        loadingCheckout: state.order.loadingCheckout,
        loginDetails: state.login.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        checkout: payload => dispatch(checkout.request(payload)),
        responseDismiss: () => dispatch(set_order_state({ checkout_response: null })),
        customerDetails: payload => dispatch(get_customer_details.request(payload)),
        changeLang: (lng) => dispatch(changeLang(lng)),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
 
export default enhance(CheckoutForm);