import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect,withRouter } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Banner from '../Banner/Banner';
import { addLoginDetails } from '../../Helper/Helper'
import { page } from "../../redux/reducers/setting";
import {
    get_featured_restaurant_data
} from "../../redux/reducers/featured_restaurant_list"; 
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import Slider from "react-slick";



  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      />
    );
  }

class Home extends Component {

	constructor(props) {
        super(props);
        this.state = { 
            data : [],
            lang : props.lang,
            restaurantLimit : 30
        }
    }

    componentDidMount(){
        const { getFeaturedRestaurantData,history} = this.props;
        getFeaturedRestaurantData({restaurantLimit:this.state.restaurantLimit});
    }
    checkUserStatus = ()=>{
        const { page , loginDetails, history } = this.props
        if(loginDetails){
            history.push(`/became-an-ambassador`);
        }else{
            page("became-an-ambassador");
            history.push(`/login`);
        }
    }

    checkBookUserStatus = (fid)=>{
        const { page , loginDetails, history } = this.props
        if(loginDetails){
            history.push('/book-table/'+fid);
        }else{
            page("book-table/"+fid);
            history.push(`/login`);
        }
    }

    render() { 
        const { t, restaurant_data, featured_restaurant_data, lang, getRestaurantData, getFeaturedRestaurantData } = this.props;
        if(lang != this.state.lang){
        	this.setState({
        		lang : lang
        	},()=>{
        		// getRestaurantData();
        		getFeaturedRestaurantData({restaurantLimit:this.state.restaurantLimit}); 
        	});

        }

        const settings = {
            dots: false,
            //infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            // nextArrow: <SampleNextArrow />,
            // prevArrow: <SamplePrevArrow />,
            appendArrows: ".featured-ambassador-slider-controlar-wrapper",
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                }
                },
                {
                  breakpoint: 1000,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };

        return ( 
            <>
            <Header />
            <Banner />
			<section className="featured-restaurant">
                <div className="container">
                    <div className="row">

                    <div className="col-lg-12">
                        <h4 className="section-heading"><div dangerouslySetInnerHTML={{ __html: t("featured_restaurants") }} />
                            <div className="slider-controlar-wrapper featured-ambassador-slider-controlar-wrapper"></div>
                        </h4> 
                    </div>
                    <div className="col-lg-12">
                            <div className="slider-wrapper">
                                <Slider {...settings}>
                                    {featured_restaurant_data && featured_restaurant_data.responseStatus.STATUS == "SUCCESS" && featured_restaurant_data.responseData.map((featuredVal) => (
                                        <div key={featuredVal.id}>
                                            <div className="featured-box">
                                                <div className="image-area">
                                                    {featuredVal.ambassadorImg != "" &&
                                                    <>
                                                    <div className="ambassador-profile-pic-holder big">
                                                        <div className="ambassador-profile-pic">
                                                            <img src={featuredVal.ambassadorImg} alt="" />
                                                        </div>
                                                        <div className="ambassador-hat">
                                                            <img src="assets/images/ambassado-icon.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    </>
                                                    }
                                                    <img src={featuredVal.restaurant_image} alt="{featuredVal.name}" />
                                                </div>
                                                <div className="featured-content-area">
                                                    <h5><a>{featuredVal.name}</a></h5>
                                                    <h6 className="foodtype">{t("cuisine")}: {featuredVal.cuisines}</h6>
                                                    <div className="chef-profile">
                                                        <div className="chef-profile-pic-holder">
                                                            <div className="chef-profile-pic">
                                                                <img src={featuredVal.chef_image} alt="{featuredVal.chef_name}" />
                                                            </div>
                                                            <div className="chef-hat">
                                                                <img src="assets/images/Hat.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="chef-name">{featuredVal.chef_name}</div>
                                                    </div>
                                                    <div className="featured-action-area">
                                                        <ul>
                                                            <li>
                                                                <Link to={`/products/${featuredVal.id}`} disabled={featuredVal.is_order=='N'}>
                                                                    {/*<img src="assets/images/book-online-icon.png" alt="" />*/}<span>{t("order_online")}</span>
                                                                </Link>
                                                            </li>
                                                            {/*<li>
                                                                <a style={{cursor:"pointer"}} onClick={() => this.checkBookUserStatus(featuredVal.id)} disabled={featuredVal.is_book=='N'}>*/}
                                                                    {/* <img src="assets/images/book-table-icon.png" alt="" /> */}{/*<span>{t("book_a_table")}</span>*/}
                                                                {/*</a>
                                                            </li>*/}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* {featured_restaurant_data && featured_restaurant_data.responseStatus.STATUS == "FAILED" &&
                                        <div className="col-md-12" ><h4>{t("restaurant_not_found")}</h4></div>
                                    } */}
                                    {/* {featured_restaurant_data && featured_restaurant_data.responseStatus.STATUS == "SUCCESS" && featured_restaurant_data.responseData.map((featuredVal) => (
                                        <div key={featuredVal.id}>
                                            <div className="featured-box">
                                                <div className="image-area">
                                                    <div className="ambassador-profile-pic-holder big">
                                                        <div className="ambassador-profile-pic">
                                                            <img src="assets/images/ambassador-1.jpg" alt="" />
                                                        </div>
                                                        <div className="ambassador-hat">
                                                            <img src="assets/images/ambassado-icon.svg" alt="" />
                                                        </div>
                                                    </div>
                                                    <img src={featuredVal.restaurant_image} alt="{featuredVal.name}" />
                                                </div>
                                                <div className="featured-content-area">
                                                    <h5><a href="#">{featuredVal.name}</a></h5>
                                                    <h6 className="foodtype">{t("cuisine")}: {featuredVal.cuisines}</h6>
                                                    <div className="chef-profile">
                                                        <div className="chef-profile-pic-holder">
                                                            <div className="chef-profile-pic">
                                                                <img src={featuredVal.chef_image} alt="{featuredVal.chef_name}" />
                                                            </div>
                                                            <div className="chef-hat">
                                                                <img src="assets/images/Hat.png" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="chef-name">{featuredVal.chef_name}</div>
                                                    </div>
                                                    <div className="featured-action-area">
                                                        <ul>
                                                            <li>
                                                                <a href="#" disabled={featuredVal.is_book=='N'}>
                                                                    <img src="assets/images/book-online-icon.png" alt="" /> {t("book_online")}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" disabled={featuredVal.is_order=='N'}>
                                                                    <img src="assets/images/book-table-icon.png" alt="" />{t("book_table")}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}
                                    {/* {featured_restaurant_data && featured_restaurant_data.responseStatus.STATUS == "FAILED" &&
                                        <div className="col-md-12" ><h4>{t("restaurant_not_found")}</h4></div>
                                    } */}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			
            <section className="home_featbanner_wraper">
                <div className="container-fluid">
                    <div className="home_featbanner">
                        <div className="row">
                            <div className="col-md-4 home_left_stayh"><img src="assets/images/st_home.jpg" alt="" /></div>
                            <div className="col-md-5 home_center_stayh"><div className=""><p>{t('home_order_text')}</p>

                            <Link to="/restaurant-list/" styl={{color:"#FFFFFF !important"}} className="btn btn-solid col-md-2">ORDER</Link>
                            </div></div>
                            <div className="col-md-3 home_right_stayh"><img src="assets/images/basket_fr.png" alt="" /></div>
                        </div>
                    </div>
                </div>
            </section>
      

	            
            <section className="home-ambasedor-area type-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h4 className="section-heading homeamb_gap">
                                <img src="assets/images/ambassado-icon.svg" />
                                <div dangerouslySetInnerHTML={{ __html: t("become_an_ambassador_home") }} /></h4>
                        </div>
                    </div>
                </div>
                <div className="banner-area">
                    <img src="assets/images/ambasedor-banner.jpg" alt="" />
                    <div className="banner-white-box-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="banner-white-box">
                                        <p> <span dangerouslySetInnerHTML={{ __html: t("ambassador_text_home") }} /> </p>
                                        <a onClick={this.checkUserStatus} className="btn btn-solid float-right">{t("get_started")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            
            
            
            <section className="footer-tp-app-sctn">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="app-content">
                                <h4><div dangerouslySetInnerHTML={{ __html: t("home_download_heading") }} /></h4>
                                <p>{t("home_download_text")}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <ul className="app-download-btn-list">
                                <li>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.angeler.app">
                                        <img src="assets/images/google-play-icon.jpg" alt={t("google_play_store")} />
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <img src="assets/images/app-store-iocn.jpg" alt={t("app_store")} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <div className="mobile-pic">
                                <img src="assets/images/mobile-icon.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

	        <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
	return {
        lang: state.setting.lng,
        featured_restaurant_data: state.featuredRestaurant.get_response_featured_restaurant,
        loading: state.restaurant.loading,
        response: state.restaurant.response,
        error: state.restaurant.error,
        loginDetails: state.login.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getFeaturedRestaurantData: payload => dispatch(get_featured_restaurant_data.request(payload)),
        page: payload => dispatch(page(payload)),
    }
  }

const enhance = compose(
    withNamespaces(),
    withRouter,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
); 
export default enhance(Home);
// export default withNamespaces()(Home);
