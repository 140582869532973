import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBanner from '../SearchBanner/SearchBanner';
import GoogleMap from '../GoogleMap/GoogleMap';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Slider from "react-slick";
import { get_user_commission_list_data } from "../../redux/reducers/login";
import { addLoginDetails } from '../../Helper/Helper'


class Commission extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data : [],
            lang : props.lang,
            ambId : null,
        }
    }

    componentDidMount(){
    	const { getUserCommissionListData,history} = this.props;
        
        const { id } = this.props.match.params
        this.setState({ ambId: id },() => {
        	// console.log(this.state.ambId);
            getUserCommissionListData({ambId:this.state.ambId});
        });

        let loggedDetails = addLoginDetails();
        let authorized = false;
        if(loggedDetails){
          authorized = loggedDetails.session_id ? true : false;
        } 
        if(!authorized){
            history.push(`/login`);
        }   
	}
    
    render() { 
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            
        };

        const { t, lang, get_user_commission_data, getUserCommissionListData, loginDetails, ambId } = this.props;
        if(lang != this.state.lang){
        	// console.log(lang, this.state.lang)
        	this.setState({
				lang : lang,
			},()=>{
				// console.log(this.state.lang)
        		getUserCommissionListData({ambId:this.state.ambId});
        	});

        }
           
         console.log(get_user_commission_data);   

        // const [startDate, setStartDate] = useState(new Date());
        return ( 
            <>
            <Header />
            <SearchBanner />

	            <div className="dashboard">
			        <div className="container">
			            
			            <div className="row d-flex justify-content-center marginbottom-40">
			                <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4">
			                    <div className="border-box">
			                        <div className="form-section-head-tag"><span className="blue-gradient-bg">{t('restaurant_details')}</span></div>
			                        <div className="profile-details">
			                        {get_user_commission_data && get_user_commission_data.responseStatus.STATUS == "SUCCESS" && get_user_commission_data.responseData.restaurantData &&
			                        	<>
			                            <div className="profile-details-content">
			                                <ul>
			                                    <li><img src={get_user_commission_data.responseData.restaurantData.restaurant_image} alt="" /></li>
			                                    <li><strong>{t('commission_book_rest_name')}:</strong> {get_user_commission_data.responseData.restaurantData.name}</li>
			                                    <li><strong>{t('commission_restaurant_address')}:</strong> {get_user_commission_data.responseData.restaurantData.address}</li>
			                                    <li><strong style={{minWidth:"0"}}>{t("hours")}:</strong> {get_user_commission_data.responseData.restaurantData.schedule}</li>
			                                    <li><strong>{t('total_commission')}:</strong> {get_user_commission_data.responseData.restaurantData.totalCommission}</li>
			                                </ul>
			                            </div>
			                            </>
			                        }
			                        </div>
			                    </div>
			                </div>
			            </div>
			            
			            <div className="row d-flex justify-content-center marginbottom-40">
			                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			                    <div className="border-box">
			                        <div className="form-section-head-tag marginbottom-30"><span className="blue-gradient-bg">{t('dashboard_book_table')}</span></div>
			                        <div className="profile-details pt-0 pb-0  mt-0">
			                            <div className="profile-details-content p-0">
			                                <div className="table-responsive">
			                                    <table className="table mb-0 table-striped dashboard-front">
			                                        <thead>
			                                            <tr className="table-primary">
			                                                <th>{t('dashboard_book_name')}</th>
			                                                <th>{t('dashboard_book_person')}</th>
			                                                <th>{t('dashboard_book_datetime')}</th>
			                                                <th>Email</th>
			                                                <th>{t('dashboard_book_phone')}</th>
			                                                <th>{t('dashboard_book_commission')}</th>
			                                            </tr>
			                                        </thead>
			                                        <tbody>
			                                        	{get_user_commission_data && get_user_commission_data.responseStatus.STATUS == "SUCCESS" && get_user_commission_data.responseData.bookTableData.map((val) => (
			                                            <tr key={val.id}>
			                                                <td>{val.name}</td>
			                                                <td>{val.persons}</td>
			                                                <td>{val.booked_date} ({val.booked_time})</td>
			                                                <td>{val.email}</td>
			                                                <td>{val.phone}</td>
			                                                <td style={{textAlign:"right"}}>{val.ambassador_commission}</td>
			                                            </tr>
			                                        	))}
			            								{get_user_commission_data && (get_user_commission_data.responseStatus.STATUS == "FAILED" || !get_user_commission_data.responseData.bookTableData) &&
													        <tr>
			                                                <td colSpan="7">{t("data_not_found")}</td>
			                                                </tr>
													    }
			                                        </tbody>
			                                    </table>
			                                </div>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			            </div>
			            
			            
			        </div>
			    </div>
 
            <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        get_user_commission_data: state.login.response_get_user_commission_list_data,
        loading: state.restaurant.loading,
        response: state.restaurant.response,
        error: state.restaurant.error, 
        loginDetails: state.login.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getUserCommissionListData: payload => dispatch(get_user_commission_list_data.request(payload)),
    }
  }

    const enhance = compose(
        withRouter,
        withNamespaces(),
        connect(
        mapStateToProps,
        mapDispatchToProps,
        
        )
    );

export default enhance (Commission);

  
  




