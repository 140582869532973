import React, { Component } from 'react';
import './App.css';
import { withNamespaces } from 'react-i18next';
import ReactNotification from 'react-notifications-component'
import { Provider } from 'react-redux'
import store from './redux/store'
import {
  BrowserRouter
} from "react-router-dom";
import Router from "./Router";
import ScrollToTop from 'react-router-scroll-top'
import 'react-notifications-component/dist/theme.css'
import { addLoginDetails } from './Helper/Helper'
import { compose } from "recompose";
import { connect } from "react-redux";
import { set_auth_state } from "./redux/reducers/login";

class App extends Component {
  componentDidMount(){
    const { t, setLoginDetails } = this.props;
    let loggedDetails = addLoginDetails();
    if(Object.entries(loggedDetails).length > 0 && loggedDetails.constructor === Object){
      setLoginDetails(loggedDetails);
    }
  }
  render(){
    const { t, loginDetails } = this.props;
    let loggedDetails = addLoginDetails();
    let authorized = false;
    // console.log(loginDetails)
    if(loginDetails){
      authorized = loginDetails.session_id ? true : false;
    } 
    else if(loggedDetails){
      authorized = loggedDetails.session_id ? true : false;
    }
    // console.log(authorized)
    return (
        <>
          <ScrollToTop />
          <ReactNotification />
          <Router authorized={authorized} />
        </>
      );
  } 





      /* render(){
        const { t } = this.props;
        return (
          <Provider store={store}>
            <BrowserRouter>
                <ReactNotification />
                <ScrollToTop />
                <Router />
            </BrowserRouter>
          </Provider>
          );
      } */
  
}

const mapStateToProps = state => {
  return {
      loginDetails: state.login.login_details,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoginDetails: payload => dispatch(set_auth_state({ login_details: payload })),
  }
}

const enhance = compose(
  withNamespaces(),
  connect(
      mapStateToProps,
      mapDispatchToProps
  )
);

export default enhance(App);
