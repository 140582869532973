import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Formik } from "formik";
import * as Yup from 'yup';
import FocusError from "../../Utility/FocusError" 
import { phoneValidator, showNotification } from '../../../Helper/Helper';
import { post_contact_data, set_contact_state } from "../../../redux/reducers/contact_us";




class ContactUsForm extends Component {
    constructor(props) {
        super(props);
        this.resetForm = '';
        this.state = {
            lang : props.lang,
            postValue : null,
            setLang : null
        }
    }

    

    render(){
        const { t, lang, loginDetails, postContactData, post_response_contact_data, responseDismiss, loading } = this.props;
        if(lang != this.state.lang){
            this.setState({
                lang : lang,
            },()=>{
                if(lang == 'en'){
                    var language = 'english';
                }else{
                    var language = 'french';
                }
                this.resetForm();
            });
        }

        

        

        
        

        if(post_response_contact_data && post_response_contact_data.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),post_response_contact_data.responseStatus.MESSAGE,'success','top-left');
            responseDismiss();
            this.resetForm();
          }
            
          if(post_response_contact_data && post_response_contact_data.responseStatus.STATUS == "FAILED"){
            showNotification(t("error"),post_response_contact_data.responseStatus.MESSAGE,'danger','top-left');
            responseDismiss();
          }
        const initialValues = {
            name:'',
            email:'',
            phone:'',
			contact_message:''
        };
          // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
          const phoneRegExp = /^[0-9]{8,15}$/
          const validationSchema = Yup.object().shape({
                
                name: Yup.string()
                    .max(50, t("must_be_50_chars"))
                    .required(t('required_field')),
                email: Yup.string()
                    .email(t("invalid_email"))
                    .required(t('required_field')),
                phone: Yup.string()
                    .matches(phoneRegExp, t("invalid_contact"))
                    .required(t('required_field')),
				contact_message: Yup.string()
                    .max(50, t("must_be_50_chars"))
                    .required(t('required_field')),
          });
          
        return ( 
            
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values, form) => {
					console.log(values);
                    postContactData(values)
                }}>
                {formikProps => {
                    this.resetForm = formikProps.resetForm
                const {
                    values,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                } = formikProps;
                
                    return(
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="booking-form-section">
                                    <h6 className="dark-gray booking-form-heading"><strong>{t('contact_form')}</strong></h6>
                                    <div className="form-section">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                className="form-control btm-outline" 
                                                placeholder={t('name')} 
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            {errors.name ? (
                                                <div className="error-msg">{errors.name}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                className="form-control btm-outline" 
                                                placeholder={t('email')} 
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            {errors.email ? (
                                                <div className="error-msg">{errors.email}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                className="form-control btm-outline" 
                                                placeholder={t('phone_no')} 
                                                name="phone"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.phone}
                                            />
                                            {errors.phone ? (
                                                <div className="error-msg">{errors.phone}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group">
											<textarea className="form-control btm-outline" cols="1" rows="5" 
											name="contact_message" onChange={handleChange}
											onBlur={handleBlur}
											placeholder={t("message")}
											value={values.contact_message} ></textarea>
											{errors.contact_message ? (
												<div className="error-msg">{errors.contact_message}</div>
											) : null}
										</div>
                                        
                                    </div>
									{loading ? 
                                    <button className="btn btn-solid col-md-3 contact_btn disable-link" disabled type="button">{t("submit")}</button> :
                                    <button className="btn btn-solid col-md-3 contact_btn" type="submit">{t("submit")}</button>}
								</div>
                            </form>
                        </>
                    );
                }}
			</Formik>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        post_response_contact_data: state.contact.post_response_contact_data,
        loading: state.contact.loading,
        response: state.contact.response,
        error: state.contact.error,
        loginDetails: state.login.login_details,
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        postContactData: payload => dispatch(post_contact_data.request(payload)),
        responseDismiss: () => dispatch(set_contact_state({ post_response_contact_data: null })),
    }
}

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(ContactUsForm);