import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withNamespaces, Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from 'yup';
import { phoneValidator, showNotification } from '../../../Helper/Helper';
import {
    change_password, set_customer_state
} from "../../../redux/reducers/customer";


class ChangePassord extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
         }
         this.resetFormData = ''
    }
    render() { 
        const { t, lang, loading, change_password_response, changePassword, onClose, responseDismiss } =this.props;
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                this.resetFormData()
            })
        }
        if(change_password_response && change_password_response.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),change_password_response.responseStatus.MESSAGE,'success');
            responseDismiss();
            onClose();  
        }else if(change_password_response && change_password_response.responseStatus.STATUS == "FAILED"){
            let error_msg = change_password_response.responseStatus.MESSAGE.replace(",", ", ");
            showNotification(t("error"),error_msg,'danger');
            responseDismiss();
        }
        const initialValues = {
              password : '',
              password_confirmation : ''
        };
        const validationSchema = Yup.object().shape({
            password: Yup.string()
                .min(8, t("password_should_be_8_chars_or_more"))
                .required(t("required")),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref("password"), null], t("password_not_match"))
                .required(t("required")),
        });
        return ( 
            <Formik
            // enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, form) => {
                // console.log(values)
                changePassword(values)
            }}>
            {formikProps => {
                this.resetFormData = formikProps.resetForm
              const {
                values,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              } = formikProps;
              return ( 
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>{t("password")}</label><input type="password" className="form-control" 
                                name="password" onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password} />
                                {errors.password ? (
                                    <div className="error-msg">{errors.password}</div>
                                ) : null}
                            </div>
                            <div className="form-group">
                                <label>{t("password_confirmation")}</label><input type="password" className="form-control" 
                                name="password_confirmation" onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password_confirmation} />
                                {errors.password_confirmation ? (
                                    <div className="error-msg">{errors.password_confirmation}</div>
                                ) : null}
                            </div>
                            <div className="form-group text-center">
                                {loading ? 
                                <button className="btn btn-rounded-yellow disable-link" disabled type="submit">{t("update")}</button>:
                                <button className="btn btn-solid" type="submit">{t("update")}</button>}
                            </div>
                        </div>
                    </form>
                    );
                }}
            </Formik>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        loading: state.customer.loading,
        change_password_response: state.customer.change_password_response,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        changePassword: payload => dispatch(change_password.request(payload)),
        responseDismiss: () => dispatch(set_customer_state({ change_password_response: null })),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(ChangePassord);