import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withNamespaces, Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from 'yup';
import { phoneValidator, showNotification } from '../../../Helper/Helper';
import {
    edit_shipping_address, set_customer_state
} from "../../../redux/reducers/customer";

class ShippingAddressForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
         }
         this.resetFormData = ''
    }
    render() { 
        const { t, lang , customerDetail, loading, edit_shipping_address_response, editShippingAddress, onClose, responseDismiss} =this.props;
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                this.resetFormData()
            })
        }
        if(edit_shipping_address_response && edit_shipping_address_response.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),edit_shipping_address_response.responseStatus.MESSAGE,'success');
            responseDismiss();
            onClose();  
        }else if(edit_shipping_address_response && edit_shipping_address_response.responseStatus.STATUS == "FAILED"){
            let error_msg = edit_shipping_address_response.responseStatus.MESSAGE.replace(",", ", ");
            showNotification(t("error"),error_msg,'danger');
            responseDismiss();
        }
        const initialValues = {
            shipping_first_name: customerDetail ? customerDetail.responseData.shipping_first_name : '',
            shipping_last_name : customerDetail ? customerDetail.responseData.shipping_last_name : '',
            shipping_phone : customerDetail ? customerDetail.responseData.shipping_phone : '',
            shipping_postal_code : customerDetail ? customerDetail.responseData.shipping_postal_code : '',
            shipping_country : customerDetail ? customerDetail.responseData.shipping_country : '',
            shipping_city : customerDetail ? customerDetail.responseData.shipping_city : '',
            shipping_address : customerDetail ? customerDetail.responseData.shipping_address : ''
        }
        const validationSchema = Yup.object().shape({
            shipping_first_name: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t('required')),
            shipping_last_name: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t("required")),
            shipping_phone: Yup.string()
                .max(10, t("phone_must_be_10_chars"))
                .matches(phoneValidator, t("invalid_phone"))
                .required(t("required")),
            shipping_postal_code: Yup.string()
                .max(10, t("must_be_10_chars"))
                .required(t('required')),
            shipping_country: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t("required")),
            shipping_city: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t("required")),
            shipping_address: Yup.string()
                .max(100, t("must_be_100_chars"))
                .required(t('required'))
        });
        return ( 
            <Formik
            // enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, form) => {
                // console.log(values)
                editShippingAddress(values)
            }}>
            {formikProps => {
                this.resetFormData = formikProps.resetForm
              const {
                values,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              } = formikProps;
              return ( 
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t("first_name")}</label><input type="text" className="form-control" 
                                        name="shipping_first_name" onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.shipping_first_name} />
                                        {errors.shipping_first_name ? (
                                            <div className="error-msg">{errors.shipping_first_name}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t("last_name")}</label><input type="text" className="form-control" 
                                        name="shipping_last_name" onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.shipping_last_name} />
                                        {errors.shipping_last_name ? (
                                            <div className="error-msg">{errors.shipping_last_name}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t("phone")}</label><input type="text" className="form-control" 
                                        name="shipping_phone" onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.shipping_phone} />
                                        {errors.shipping_phone ? (
                                            <div className="error-msg">{errors.shipping_phone}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t("postal_code")}</label><input type="text" className="form-control" 
                                        name="shipping_postal_code" onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.shipping_postal_code} />
                                        {errors.shipping_postal_code ? (
                                            <div className="error-msg">{errors.shipping_postal_code}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t("country")}</label><input type="text" className="form-control" 
                                        name="shipping_country" onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.shipping_country} />
                                        {errors.shipping_country ? (
                                            <div className="error-msg">{errors.shipping_country}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t("city")}</label><input type="text" className="form-control" 
                                        name="shipping_city" onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.shipping_city} />
                                        {errors.shipping_city ? (
                                            <div className="error-msg">{errors.shipping_city}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group marginbottom-30">
                                        <label>{t("address")}</label><textarea className="form-control" cols="1" rows="5" 
                                        name="shipping_address" onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.shipping_address} >
                                            </textarea>
                                            {errors.shipping_address ? (
                                                <div className="error-msg">{errors.shipping_address}</div>
                                            ) : null}
                                    </div>
                                </div>
                            </div>


                            <div className="form-group text-center">
                                {loading ? 
                                <button className="btn btn-rounded-yellow disable-link" disabled type="submit">{t("update")}</button>:
                                <button className="btn btn-solid" type="submit">{t("update")}</button>}
                            </div>
                        </div>
                    </form>
                );
            }}
        </Formik>
      );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        loading: state.customer.loading,
        edit_shipping_address_response: state.customer.edit_shipping_address_response,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        editShippingAddress: payload => dispatch(edit_shipping_address.request(payload)),
        responseDismiss: () => dispatch(set_customer_state({ edit_shipping_address_response: null })),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(ShippingAddressForm);