import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';



class ContactGoogleMap extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { restaurant_details_data } = this.props
        const mapStyles = {
            width: '600',
            height: '600',
        };
        return (
            <>
            <Map
            google={this.props.google}
            zoom={8}
            style={mapStyles}
            initialCenter={{ lat: 22.612381, lng: 88.407955}}
            // initialCenter = {{lat:22.612381, lng:88.407955}}
            >
                <Marker position={{ lat: 22.612381, lng: 88.407955}} />
            </Map>
            
            </>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDjh3KIBDNC4juNrhzTbMvvInsB6Uw8jKA'
  })(ContactGoogleMap);