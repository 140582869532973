import api from "angelerapi";

import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    auth_check_user_data, auth_check_user_balance
} from "../reducers/login";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";

function* authCheckUserData({payload}){
  let lang = localStorage.getItem("i18nextLng");
  try {

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    
    const res = yield api.post("/validate_user",payload,{ headers:headers }
    );

    yield put(auth_check_user_data.success({ response_auth_check_user_data: res.data }));
  } catch (err) {
    yield put(auth_check_user_data.failure({ error: formatError(err) }));
  }
}

function* watchAuthCheckUserData(){
  yield takeLatest(types.AUTH_CHECK_USER_DATA.REQUEST, authCheckUserData);
}

function* authCheckUserBalance({payload}){
  let lang = localStorage.getItem("i18nextLng");
  try {

    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    
    const res = yield api.post("/book_table",payload,{ headers:headers }
    );

    yield put(auth_check_user_balance.success({ response_auth_check_user_balance: res.data }));
  } catch (err) {
    yield put(auth_check_user_balance.failure({ error: formatError(err) }));
  }
}

function* watchAuthCheckUserBalance(){
  yield takeLatest(types.AUTH_CHECK_USER_BALANCE.REQUEST, authCheckUserBalance);
}


export default function* (){
    yield all([
        watchAuthCheckUserData(),
        watchAuthCheckUserBalance()
    ]);
};