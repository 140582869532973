import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { BrowserRouter as Router, Switch, Route, Link , withRouter} from "react-router-dom";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const { t } = this.props;
        return ( 
            <footer>
                <div className="footer-social-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="footer-social">
                                    <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-link-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <ul className="footer-link-list">
                                    <li><Link to="/about-us">{t("about_us")}</Link></li>
                                    <li><Link to="/faq">{t("faq")}</Link></li>
                                    <li><Link to="/contact-us">{t("contact_us")}</Link></li>
                                    <li><Link to="/terms-service">{t("terms_of_services")}</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <p className="footer-copywrite">{t("copyright")} 2020 © <a href="#" title="angeler" target="_blank">AngelerFood.com</a>, {t("all_rights_reserved")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


         );
    }
}
 
export default withNamespaces()(Footer);