import axios from "axios";

const lang = localStorage.getItem("i18nextLng");
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

// export const setLanguage = () => {
//   const lang = localStorage.getItem("i18nextLng");
//   api.defaults.headers.common["Accept-Language"] = lang;
// };

api.defaults.xsrfCookieName = "csrftoken";
api.defaults.xsrfHeaderName = "X-CSRFToken";
api.defaults.headers.common["x-lang"] = lang;

api.interceptors.response.use(function (response){
  return response;
}
// , async function (error){
//   if(error.response.status === 401) {
//     store.dispatch(get_logout.success());
//     history.push('/login');
//   }
//   return Promise.reject(error);

// }
);

export default api;
