import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
  POST_AMBASSADOR_DATA: createAsyncTypes("POST_AMBASSADOR_DATA"),
  SET_AMBASSADOR_STATE: "SET_AMBASSADOR_STATE",
};

export const post_ambassador_data = createAsyncActions("POST_AMBASSADOR_DATA");
export const set_ambassador_state = val => ({
    type: types.SET_AMBASSADOR_STATE,
    state: val
});

const initialState = {
    post_response_ambassador: null,
    loading: null,
    response: null,
    error: null
};

const ambassador = (state = initialState, action) => {
    switch (action.type) {  
	  case types.SET_AMBASSADOR_STATE:
		return {
			...state,
			...action.state
		}; 
      case types.POST_AMBASSADOR_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.POST_AMBASSADOR_DATA.SUCCESS:
        return {
          ...state,
          post_response_ambassador: action.payload.post_response_ambassador,
          loading: null
        };
      case types.POST_AMBASSADOR_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      default:
            return state;
      }
};

export default ambassador;