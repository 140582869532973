import { combineReducers } from 'redux'
import settingReducer from './reducers/setting'
import bakeryReducer from './reducers/bakery'
import ambassadorReducer from './reducers/ambassador'
import restaurantReducer from './reducers/restaurant'
import featuredRestaurantReducer from './reducers/featured_restaurant_list'
import orderReducer from './reducers/order'
import loginReducer from './reducers/login'
import customerReducer from './reducers/customer'
import contactUs from './reducers/contact_us'

const rootReducer = combineReducers({
  setting: settingReducer,
  bakery: bakeryReducer,
  ambassador: ambassadorReducer,
  restaurant: restaurantReducer,
  featuredRestaurant: featuredRestaurantReducer,
  order: orderReducer,
  login: loginReducer,
  customer: customerReducer,
  contact:contactUs,
  
})

export default rootReducer