import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
  POST_CONTACT_DATA: createAsyncTypes("POST_CONTACT_DATA"),
  SET_CONTACT_STATE: "SET_CONTACT_STATE",
};

export const post_contact_data = createAsyncActions("POST_CONTACT_DATA");
export const set_contact_state = val => ({
    type: types.SET_CONTACT_STATE,
    state: val
});
const initialState = {
    post_response_contact_data: null,
    loading: null,
    response: null,
    error: null
};

const contact_us = (state = initialState, action) => {
    switch (action.type) {  
	  case types.SET_CONTACT_STATE:
        return {
            ...state,
            ...action.state
        };
      case types.POST_CONTACT_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.POST_CONTACT_DATA.SUCCESS:
        return {
          ...state,
          post_response_contact_data: action.payload.post_response_contact_data,
          loading: null
        };
      case types.POST_CONTACT_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      default:
            return state;
      }
};

export default contact_us;