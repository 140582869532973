import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
  GET_RESTAURANT_DATA: createAsyncTypes("GET_RESTAURANT_DATA"),
  GET_RESTAURANT_PRODUCT_DATA: createAsyncTypes("GET_RESTAURANT_PRODUCT_DATA"),
  GET_RESTAURANT_DETAILS_DATA: createAsyncTypes("GET_RESTAURANT_DETAILS_DATA"),
  POST_BOOK_TABLE_DATA: createAsyncTypes("POST_BOOK_TABLE_DATA"),
  SET_RESTAURANT_STATE: "SET_RESTAURANT_STATE",
};

export const get_restaurant_data = createAsyncActions("GET_RESTAURANT_DATA");
export const get_restaurant_product_data = createAsyncActions("GET_RESTAURANT_PRODUCT_DATA");
export const get_restaurant_details_data = createAsyncActions("GET_RESTAURANT_DETAILS_DATA");
export const post_book_table_data = createAsyncActions("POST_BOOK_TABLE_DATA");
export const set_restaurant_state = val => ({
  type: types.SET_RESTAURANT_STATE,
  state: val
});

const initialState = {
    get_response_restaurant: null,
    get_response_product: null,
    get_response_restaurant_details: null,
    post_response_book_table: null,
    loading: null,
    response: null,
    error: null
};

const restaurant = (state = initialState, action) => {
    switch (action.type) {  
      case types.SET_RESTAURANT_STATE:
        return {
            ...state,
            ...action.state
        }; 
      case types.GET_RESTAURANT_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_RESTAURANT_DATA.SUCCESS:
        return {
          ...state,
          get_response_restaurant: action.payload.get_response_restaurant,
          loading: null
        };
      case types.GET_RESTAURANT_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      case types.GET_RESTAURANT_PRODUCT_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_RESTAURANT_PRODUCT_DATA.SUCCESS:
        return {
          ...state,
          get_response_product: action.payload.get_response_product,
          loading: null
        };
      case types.GET_RESTAURANT_PRODUCT_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      case types.GET_RESTAURANT_DETAILS_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_RESTAURANT_DETAILS_DATA.SUCCESS:
        return {
          ...state,
          get_response_restaurant_details: action.payload.get_response_restaurant_details,
          loading: null
        };
      case types.GET_RESTAURANT_DETAILS_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      
      
      case types.POST_BOOK_TABLE_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.POST_BOOK_TABLE_DATA.SUCCESS:
        return {
          ...state,
          post_response_book_table: action.payload.post_response_book_table,
          loading: null
        };
      case types.POST_BOOK_TABLE_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };




      default:
            return state;
      }
};

export default restaurant;