import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
  GET_FEATURED_RESTAURANT_DATA: createAsyncTypes("GET_FEATURED_RESTAURANT_DATA"),
};

export const get_featured_restaurant_data = createAsyncActions("GET_FEATURED_RESTAURANT_DATA");

const initialState = {
    get_response_featured_restaurant: null,
    loading: null,
    response: null,
    error: null
};

const featured_restaurant = (state = initialState, action) => {
    switch (action.type) {  
      case types.GET_FEATURED_RESTAURANT_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_FEATURED_RESTAURANT_DATA.SUCCESS:
        return {
          ...state,
          get_response_featured_restaurant: action.payload.get_response_featured_restaurant,
          loading: null
        };
      case types.GET_FEATURED_RESTAURANT_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      default:
            return state;
      }
};

export default featured_restaurant;