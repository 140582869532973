import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Formik } from "formik";
import * as Yup from 'yup';
import FocusError from "../../Utility/FocusError" 
import {
    post_ambassador_data
} from "../../../redux/reducers/ambassador";
import "../Ambassador.css"
import Loader from 'react-loader-spinner'


class AmbassadorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = '';
        this.state = {
            fileName: '',
            lang : props.lang,
         };
         this.myRef = React.createRef();
    }

    fileOnChange = (e) => {
        switch (e.target.name) {
          case 'file[]':
              if(e.target.files.length > 0) {
                this.setState({ fileName: e.target.files.length });
                // setFieldValue("file", event.currentTarget.files);
            }
          break;
          default:
            this.setState({ [e.target.name]: e.target.value });
         }
      };

    handleSubmit(data) {
        const { t , postAmbassadorData } = this.props;
        var formData = new FormData();
        formData.append('user_id', data.user_id)
        formData.append('first_name', data.first_name)
        formData.append('last_name', data.last_name)
        formData.append('contact_no', data.contact_no)
        formData.append('email', data.email)
        formData.append('restaurant_name', data.restaurant_name)
        formData.append('address', data.address)
        formData.append('country', data.country)
        formData.append('city', data.city)
        formData.append('zipcode', data.zipcode)
        formData.append('restaurent_contact_no', data.restaurent_contact_no)
        formData.append('remarks', data.remarks)
        Object.keys(data.file).map((val)=>{
            formData.append('file[]',data.file[val])
        })
        postAmbassadorData({formData:formData});
        
    }
    render() { 
        const { t, loginDetails, lang, loading } = this.props;
        if(lang != this.state.lang){
            this.setState({
                lang : lang,
                fileName : null
            });
            // this.myRef = null;
            document.getElementById("file").value = "";
            this.resetForm();
            // target.value = null;
            // window.location.reload(true)

        }
        var firstName = "";
        var userId = "";
        var lastName = "";
        var userEmail = "";
        if(loginDetails){
			userId = loginDetails.user_id;
            firstName = loginDetails.first_name;
            lastName = loginDetails.last_name;
            userEmail = loginDetails.email;
        }
		

        const initialValues = {
            user_id:userId,
            first_name:firstName,
            last_name:lastName,
            contact_no:'',
            email:userEmail,
            restaurant_name:'',
            address:'',
            country:'',
            city:'',
            zipcode:'',
            restaurent_contact_no:'',
            file:'',
            remarks:''
        };
		
		// console.log(initialValues)
          // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
          const phoneRegExp = /^[0-9]{8,15}$/
          const validationSchema = Yup.object().shape({
                first_name: Yup.string()
                    .max(50, t("must_be_50_chars"))
                    .required(t('required_field')),
                last_name: Yup.string()
                    .max(50, t("must_be_50_chars"))
                    .required(t('required_field')),
                email: Yup.string()
                    .email(t("invalid_email"))
                    .required(t('required_field')),
                contact_no: Yup.string()
                    .matches(phoneRegExp, t("invalid_contact"))
                    .required(t('required_field')),
                restaurant_name: Yup.string()
                    .max(50, t("must_be_50_chars"))
                    .required(t('required_field')),
                address: Yup.string()
                    .max(100, t("must_be_100_chars"))
                    .required(t('required_field')),
                country: Yup.string()
                    .max(50, t("must_be_50_chars"))
                    .required(t('required_field')),
                city: Yup.string()
                    .max(50, t("must_be_50_chars"))
                    .required(t('required_field')),
                zipcode: Yup.string()
                    .max(50, t("must_be_50_chars"))
                    .required(t('required_field')),
                restaurent_contact_no: Yup.string()
                    .matches(phoneRegExp, t("invalid_contact"))
                    .required(t('required_field')),
                file: Yup.mixed()
                    .required(t('required_field')),


          });
          
        return ( 
        <Formik
		enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, form) => {
          const { setSubmitting } = form;
          console.log(values)
          this.handleSubmit(values)
        }}>
        {formikProps => {
            this.resetForm = formikProps.resetForm
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          } = formikProps;
          const { fileName } = this.state;
          let file = null;
          file = fileName 
        ? ( <span className="">{fileName} - {t("file_selected")}</span>) 
            : ( <span className="">{t("upload_restaurent_bill")}</span> );
            
            // lang == 'en' ? <span>{fileName} - {t("file_selected")}</span> : <span>{fileName} - Fichier (s) sélectionné</span>
          return (

                    <form onSubmit={handleSubmit} id="create-course-form">
                        <div className="form-area-wrapper">
                            
                            <div className="form-section-head-tag"><span className="blue-gradient-bg">{t("personal_details")}</span></div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="first_name"
                                            className="form-control btm-outline" 
                                            placeholder={t("first_name")} 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={firstName?firstName:values.first_name}
                                        />
                                        {errors.first_name ? (
                                            <div className="error-msg">{errors.first_name}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="last_name"
                                            className="form-control btm-outline" 
                                            placeholder={t("last_name")} 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={lastName?lastName:values.last_name} 
                                        />
                                        {errors.last_name ? (
                                            <div className="error-msg">{errors.last_name}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    name="contact_no"
                                    className="form-control btm-outline" 
                                    placeholder={t("contact_no")} 
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.contact_no} 
                                />
                                {errors.contact_no ? (
                                    <div className="error-msg">{errors.contact_no}</div>
                                ) : null}
                            </div>

                            <div className="form-group">
                                <input 
                                    type="text" 
                                    name="email"
                                    className="form-control btm-outline" 
                                    placeholder={t("email")} 
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={userEmail?userEmail:values.email} 
									
                                />
                                {errors.email ? (
                                    <div className="error-msg">{errors.email}</div>
                                ) : null}
                            </div>

                            <div className="form-section-head-tag margintop-40 marginbottom-20"><span className="blue-gradient-bg">{t("restaurant_details")}</span></div>

                            <div className="form-group">
                                <input 
                                    type="text" 
                                    name="restaurant_name"
                                    className="form-control btm-outline" 
                                    placeholder={t("name")} 
                                    onBlur={handleBlur}
                                    value={values.restaurant_name} 
                                    onChange={handleChange}
                                />
                                {errors.restaurant_name ? (
                                    <div className="error-msg">{errors.restaurant_name}</div>
                                ) : null}
                            </div>

                            <div className="form-group">
                                <input 
                                    type="text" 
                                    name="address"
                                    className="form-control btm-outline" 
                                    placeholder={t("address")}  
                                    onBlur={handleBlur}
                                    value={values.address} 
                                    onChange={handleChange}
                                />
                                {errors.address ? (
                                    <div className="error-msg">{errors.address}</div>
                                ) : null}
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="city"
                                            className="form-control btm-outline" 
                                            placeholder={t("city")} 
                                            onBlur={handleBlur}
                                            value={values.city} 
                                            onChange={handleChange}
                                        />
                                        {errors.city ? (
                                            <div className="error-msg">{errors.city}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="zipcode"
                                            className="form-control btm-outline" 
                                            placeholder={t("zip_code")} 
                                            onBlur={handleBlur}
                                            value={values.zipcode} 
                                            onChange={handleChange}
                                        />
                                        {errors.zipcode ? (
                                            <div className="error-msg">{errors.zipcode}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="country"
                                            className="form-control btm-outline" 
                                            placeholder={t("country")}  
                                            onBlur={handleBlur}
                                            value={values.country} 
                                            onChange={handleChange}
                                        />
                                        {errors.country ? (
                                            <div className="error-msg">{errors.country}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group marginbottom-40">
                                <input 
                                    type="text" 
                                    name="restaurent_contact_no"
                                    className="form-control btm-outline" 
                                    placeholder={t("contact_no")}
                                    onBlur={handleBlur}
                                    value={values.restaurent_contact_no} 
                                    onChange={handleChange}
                                />
                                {errors.restaurent_contact_no ? (
                                    <div className="error-msg">{errors.restaurent_contact_no}</div>
                                ) : null}
                            </div>

                            <div className="form-group">
                                {/* <label>{t("upload_restaurent_bill")}</label> */}
                                <div className="custom-file-upload wrapperfrmpgrp">
                                    <input 
                                        type="file" 
                                        id="file" 
                                        name="file[]" 
                                        multiple 
                                        accept="image/*"
                                        placeholder="Upload File"
                                        className="inputClass"
                                        style={{display:"none"}}
                                        ref={this.myRef}
                                        /*onChange={(event) => {
                                        setFieldValue("file", event.currentTarget.files);
                                      }}*/
                                        onChange={ (event) =>{
                                                this.fileOnChange(event) 
                                                console.log(event.currentTarget.files)
                                                setFieldValue("file", event.currentTarget.files)
                                            }
                                        }
                                    />
                                    <label htmlFor="file">{file}</label>
                                    {errors.file ? (
                                        <div className="error-msg">{errors.file}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="form-group marginbottom-40">
                                <textarea 
                                name="remarks"
                                className="form-control btm-outline" 
                                placeholder={t("remarks")}
                                value={values.remarks}
                                onChange={handleChange}
                                >
                                </textarea>
                            </div>

                            <div className="form-group text-center marginbottom-40" style={{position:"relative"}}>
								
								{loading ? 
								<><Loader className="ambassador-loader" type="Oval" color="#CCCCCC" height={40} width={40} /><input type="button" disabled className="btn btn-solid col-md-4 disable-link" value={t("submit")} /></>: 
								<input type="submit" className="btn btn-solid col-md-4" value={t("submit")} />}
								
                            </div>
							<FocusError />
                        </div>
                    </form>
          );
        }}
        </Formik>
      );
    }
}
 const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        post_response_ambassador: state.ambassador.post_response_ambassador,
        loading: state.ambassador.loading,
        response: state.ambassador.response,
        error: state.ambassador.error,
        loginDetails: state.login.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        postAmbassadorData: payload => dispatch(post_ambassador_data.request(payload)),
    }
  }

const enhance = compose(
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
 
export default enhance(AmbassadorForm);