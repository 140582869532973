import axios from "axios";

const lang = localStorage.getItem("i18nextLng");
const angelerapi = axios.create({
  // baseURL: "https://angelerapp.com/testserver/web_services/angelerFood"
  baseURL: process.env.REACT_APP_API_URL_ANGELER
});

angelerapi.defaults.xsrfCookieName = "csrftoken";
angelerapi.defaults.xsrfHeaderName = "X-CSRFToken";
angelerapi.defaults.headers.common["x-lang"] = lang;

angelerapi.interceptors.response.use(function (response){
  return response;
}

);

export default angelerapi;
