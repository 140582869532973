import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import SearchBanner from '../SearchBanner/SearchBanner';
import Header  from "../Header/Header"
import Footer  from "../Footer/Footer"
import { withRouter, Link } from "react-router-dom";
import RegisterForm from "./RegisterForm/RegisterForm";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const { t } = this.props;
        return ( 
            <>
            <Header />
            <SearchBanner />

                <div className="breadcrumb-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="breadcrumb-ul-list">
                                    <li><Link to="/"><i className="fa fa-home" aria-hidden="true"></i></Link></li>
                                    <li><span>{t('breadcrumb_registration')}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="section-block">
                    <div class="container">
                        <div class="row"> 
                            <div class="col-lg-3"></div>
                            <div class="col-lg-6">
                                <div class="shadow-box loginp_wrap">
                                    <div class="loginp_wrap_textco"><h2>Signup</h2></div>
                                    <div class="loginp_innercont">
                                        <RegisterForm />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3"></div>
                        </div>
                    </div>
                </section>
               
            <Footer />
            </>
         );
    }
}
 
export default withNamespaces()(Register);