import api from "api";

import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    get_restaurant_data,
    get_restaurant_product_data,
    get_restaurant_details_data,
    post_book_table_data
} from "../reducers/restaurant";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";

function* getRestaurantData({payload}){
  let lang = localStorage.getItem("i18nextLng");
  try {

    let url = '';
    if(Object.keys(payload).length === 0 && payload.constructor === Object){
      url = "/restaurantList";
      if('page' in payload){
        url += '?page='+payload.page
      }
    }else{
      url = "/restaurantList?searchData="+payload.searchData;
      if('page' in payload){
        url += '&page='+payload.page
      }
    }
    
    const res = yield api.get(url,{
      headers: { "x-lang": lang }
    });
    // yield api.post("/rest-auth/registration/", payload.params, {
    //   "Accept-Language": lang
    // });

    yield put(get_restaurant_data.success({ get_response_restaurant: res.data }));
  } catch (err) {
    yield put(get_restaurant_data.failure({ error: formatError(err) }));
  }
}

function* watchGetRestaurantData(){
  yield takeLatest(types.GET_RESTAURANT_DATA.REQUEST, getRestaurantData);
}

function* getRestaurantProductData({payload}){
  let lang = localStorage.getItem("i18nextLng");
  let orderid = ''
  if(payload.orderId){
    orderid = payload.orderId;
  }

  try {

    const res = yield api.get("/product-list/"+payload.id,{
      headers: { "x-lang": lang }
    });

    yield put(get_restaurant_product_data.success({ get_response_product: res.data }));
  } catch (err) {
    yield put(get_restaurant_product_data.failure({ error: formatError(err) }));
  }
}

function* watchGetRestaurantProductData(){
  yield takeLatest(types.GET_RESTAURANT_PRODUCT_DATA.REQUEST, getRestaurantProductData);
}

function* getRestaurantDetailsData({payload}){
  let lang = localStorage.getItem("i18nextLng");
  try {

    const res = yield api.get("/restaurant-details/"+payload.id,{
      headers: { "x-lang": lang }
    });

    yield put(get_restaurant_details_data.success({ get_response_restaurant_details: res.data }));
  } catch (err) {
    yield put(get_restaurant_details_data.failure({ error: formatError(err) }));
  }
}

function* watchGetRestaurantDetailsData(){
  yield takeLatest(types.GET_RESTAURANT_DETAILS_DATA.REQUEST, getRestaurantDetailsData);
}

function* postBookTableData({payload}){
  let lang = localStorage.getItem("i18nextLng");
  try {

    const res = yield api.post("/postBookTableData",payload,  
      { "x-lang": lang}
    );

    yield put(post_book_table_data.success({ post_response_book_table: res.data }));
  } catch (err) {
    yield put(post_book_table_data.failure({ error: formatError(err) }));
  }
}

function* watchPostBookTableData(){
  yield takeLatest(types.POST_BOOK_TABLE_DATA.REQUEST, postBookTableData);
}

export default function* (){
    yield all([
      watchGetRestaurantData(),
      watchGetRestaurantProductData(),
      watchGetRestaurantDetailsData(),
      watchPostBookTableData()

    ]);
};