import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withNamespaces, Trans } from 'react-i18next';
import { compose } from "recompose";
import { connect } from "react-redux";
import { getSessionOrAuthorizedToken, roundOffDecimal } from '../../../Helper/Helper'
import {
    cart_list
} from "../../../redux/reducers/order";
import { deliverAmount } from '../../../config/Config';
import { Scrollbars } from 'react-custom-scrollbars';
import { changePrice } from '../../../redux/reducers/setting'

class OrderCartList extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
         }
    }
    totalPrice(count){
        const { t } = this.props
        if(count > 1){
            var ret = count +" "+ t("articles");
            
        }else{
            var ret = count +" "+ t("article")
        }
        return ret
        
    }
    render() { 
        const { t , cart_list_data , lang, cartList, loginDetails, deliveryCharge, changePrice } = this.props;
        
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                let data = getSessionOrAuthorizedToken(loginDetails);
                cartList(data)
            })
        }
        if(!cart_list_data){
            let data = getSessionOrAuthorizedToken(loginDetails);
            cartList(data)
        }
        let total = 0;
        if(cart_list_data && cart_list_data.responseStatus.STATUS == "SUCCESS"){
            if(deliveryCharge){
                total = roundOffDecimal(parseFloat(cart_list_data.responseData.total_price)+ deliverAmount) 
            }else{
                total = roundOffDecimal(parseFloat(cart_list_data.responseData.total_price))
            }
            
        }
		changePrice(total);
        return ( 
            <>
            {cart_list_data && cart_list_data.responseStatus.STATUS == "SUCCESS" &&
            
            <div className="col-lg-4 checkout_rel checkout_pos_wrapp">
                <div className="checkout_rightcart shadow-box StickyElementAfterScroll_withfixheight cartbox-closed">
					<h4>Restaurant: {cart_list_data.responseData.orderDetails[0].restaurant_name}</h4>
                    <h5>{this.totalPrice(cart_list_data.responseData.orderDetails.length)}</h5>
                     <Scrollbars style={{ height: 180 }}>
                    {cart_list_data.responseData.orderDetails.map((val)=>(
                        <div className="row checkbox_inner_cartwrap checkout_border" key={val.order_detail_id}>
                            <div className="col-3 col-md-3"><img src={val.product_img} alt="" /></div>
                            <div className="col-9 col-md-9"><h5>{val.product_name}</h5>
                                <div className="row">
                                    <div className="col-3 col-md-3">{val.product_quantity} X</div>
                                    <div className="col-9 col-md-9 checkout_ral">&#8364; { roundOffDecimal(parseFloat(val.product_quantity) * parseFloat(val.product_price)).toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </Scrollbars>
                    <div className="row checkbox_inner_cartwrap checkout_border checkout_gtop">
                        <div className="col-8 col-md-8"><h6>{t("shippnig_charge")}</h6></div>
                        <div className="col-4 col-md-4 checkout_ral">&#8364; {deliveryCharge.toFixed(2)} </div>
                    </div>
                    <div className="row checkbox_inner_cartwrap bg_grey_tot">
                        <div className="col-4 col-md-4 Tot_text"><strong>Total</strong></div>
                        <div className="col-8 col-md-8 checkout_ral">&#8364; {total.toFixed(2)} </div>
                    </div>
                </div>
            </div>
            }
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        cart_list_data: state.order.cart_list_data,
        loginDetails: state.login.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        cartList: payload => dispatch(cart_list.request(payload)),
		changePrice: (val) => dispatch(changePrice(val)),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
 
export default enhance(OrderCartList);