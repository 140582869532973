import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Formik } from "formik";
import * as Yup from 'yup';
import FocusError from "../../Utility/FocusError" 
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { phoneValidator, showNotification, dateFormatToFranceTimeZone } from '../../../Helper/Helper';
import {
    post_book_table_data, set_restaurant_state
} from "../../../redux/reducers/restaurant";

import { auth_check_user_balance, set_auth_state } from "../../../redux/reducers/login";

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <input
            onClick={props.onClick}
            value={props.value}
            type="text"
            className="form-control btm-outline"
            readOnly={true}
        />
    )
})

const DatePickerField = React.forwardRef((props, ref) => {
    return (
        <DatePicker {...props}
            selected={props.selected}
            onChange={val => {
                props.onChange(props.name, val);
            }}
        />
    )
});

class BookTableForm extends Component {
    constructor(props) {
        super(props);
        this.resetForm = '';
        this.state = {
            minDate: new Date(),
            lang : props.lang,
            postValue : null,
            setLang : null
        }
    }

    timeFormat = (date) => {
        return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    }

    dateToTs = (date)  => {
        let dateUTC = dateFormatToFranceTimeZone(date);
        return dateUTC
    }

    componentDidMount(){
        const { authCheckUserBalance,loginDetails,restCost} = this.props;
        if(this.state.lang == 'en'){
            var language = 'english';
            this.setState({setLang:language});
        }else{
            var language = 'french';
            this.setState({setLang:language});
        }
        authCheckUserBalance({userId:loginDetails.angeler_id,serviceCost:restCost,flag:"check_balance",lang:language})
    }

    render(){
        const { t, lang,authCheckUserBalance, response_auth_check_user_balance, responseUserBalanceDismiss, loginDetails, postBookTableData, restId, restCost, post_response_book_table, responseDismiss } = this.props;
        if(lang != this.state.lang){
            this.setState({
                lang : lang,
            },()=>{
                if(lang == 'en'){
                    var language = 'english';
                }else{
                    var language = 'french';
                }
                this.resetForm();
                authCheckUserBalance({userId:loginDetails.angeler_id,serviceCost:restCost,flag:"check_balance",lang:language})
            });
        }

        if(response_auth_check_user_balance && response_auth_check_user_balance.responseStatus.STATUS == "SUCCESS" && response_auth_check_user_balance.extraData.flag == "book_table"){
            let data = this.state.postValue;
            data["transferRefNo"] = response_auth_check_user_balance.responseData.transactionRefNo;
            data["credit"] = response_auth_check_user_balance.responseData.credits;
            postBookTableData(data);
            responseUserBalanceDismiss();
            // postUserLoginData(response_auth_check_user_data.responseData);
        }

        if(response_auth_check_user_balance && response_auth_check_user_balance.responseStatus.STATUS == "FAILED" && response_auth_check_user_balance.responseStatus.STATUSCODE == "606" && response_auth_check_user_balance.extraData.flag == "book_table"){
            showNotification(t("error"),response_auth_check_user_balance.responseStatus.MESSAGE,'danger','top-left');
            responseUserBalanceDismiss();
            // postUserLoginData(response_auth_check_user_data.responseData);
        }

        var costLabel = t('charge1')+restCost.toFixed(2)+" ANC"+t('charge2');

        if(lang == "fr"){
            registerLocale("fr", fr);
        }
        var userId = "";
        var userName = "";
        var userEmail = "";
        if(loginDetails){
            userId = loginDetails.user_id
            userName = loginDetails.first_name+" "+loginDetails.last_name;
            userEmail = loginDetails.email;
        }

        if(post_response_book_table && post_response_book_table.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),post_response_book_table.responseStatus.MESSAGE,'success','top-left');
            responseDismiss();
            this.resetForm();
          }
            
          if(post_response_book_table && post_response_book_table.responseStatus.STATUS == "FAILED"){
            showNotification(t("error"),post_response_book_table.responseStatus.MESSAGE,'danger','top-left');
            responseDismiss();
          }
        const initialValues = {
            user_id:userId,
            restaurant_id:restId,
            person:'',
            book_date: new Date(),
            book_time: new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"})),
            name:userName,
            email:userEmail,
            phone:''
        };
          // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
          const phoneRegExp = /^[0-9]{8,15}$/
          const validationSchema = Yup.object().shape({
                person: Yup.string()
                    .required(t('required_field')),
                book_date: Yup.string()
                    .required(t('required_field')),
                book_time: Yup.string()
                    .required(t('required_field')),
                name: Yup.string()
                    .max(50, t("must_be_50_chars"))
                    .required(t('required_field')),
                email: Yup.string()
                    .email(t("invalid_email"))
                    .required(t('required_field')),
                phone: Yup.string()
                    .matches(phoneRegExp, t("invalid_contact"))
                    .required(t('required_field')),
          });
          
        return ( 
            
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values, form) => {
                    const { setSubmitting } = form;
                    values.booking_date = this.dateToTs(values.book_date);
                    values.booking_time = this.timeFormat(values.book_time);
                    this.setState({postValue:values})
                    authCheckUserBalance({userId:loginDetails.angeler_id,serviceCost:restCost,flag:"book_table",lang:this.state.setLang})
                    // postBookTableData(values)
                }}
                render={formikProps => {
                    this.resetForm = formikProps.resetForm
                const {
                    values,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                } = formikProps;
                
                    return(
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="booking-form-section">
                                    <h6 className="dark-gray booking-form-heading"><strong>{t('1_select_booking_details')}</strong></h6>
                                    <div className="form-section">
                                        <div className="form-group">
                                            <select className="form-control btm-outline" 
                                                name="person"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.person}
                                            >
                                                <option value="">{t('person')}</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                            {errors.person ? (
                                            <div className="error-msg">{errors.person}</div>
                                        ) : null}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 col-xs-12">
                                                <div className="form-group">
                                                    {lang == "fr" && <DatePickerField
                                                    dateFormat="yyyy/MM/dd"
                                                    minDate={this.state.minDate}
                                                    name="book_date" 
                                                    customInput={<CustomInput />}
                                                    locale="fr"
                                                    onChange={setFieldValue}
                                                    selected={values.book_date} className="form-control"
                                                    popperClassName="some-custom-class"
                                                    products                                     popperModifiers={{
                                                        offset: {
                                                        enabled: true,
                                                        offset: "5px, 10px"
                                                        },
                                                        preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: "viewport"
                                                        }
                                                    }}/>}

                                                    {lang == "en" && <DatePickerField
                                                    dateFormat="yyyy/MM/dd"
                                                    minDate={this.state.minDate}
                                                    name="book_date" 
                                                    customInput={<CustomInput />}
                                                    onChange={setFieldValue}
                                                    selected={values.book_date} className="form-control"
                                                    popperClassName="some-custom-class"
                                                    popperPlacement="top-end"
                                                    popperModifiers={{
                                                        offset: {
                                                        enabled: true,
                                                        offset: "5px, 10px"
                                                        },
                                                        preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: "viewport"
                                                        }
                                                    }}/>}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xs-12">
                                                <div className="form-group"> 
                                                    <DatePickerField
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    dateFormat="h:mm aa"
                                                    name="book_time" 
                                                    timeCaption={t('time')}
                                                    customInput={<CustomInput />}
                                                    onChange={setFieldValue}
                                                    selected={values.book_time} className="form-control"
                                                    popperClassName="some-custom-class"
                                                    popperPlacement="top-end"
                                                    popperModifiers={{
                                                        offset: {
                                                        enabled: true,
                                                        offset: "5px, 10px"
                                                        },
                                                        preventOverflow: {
                                                        enabled: true,
                                                        escapeWithReference: false,
                                                        boundariesElement: "viewport"
                                                        }
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h6 className="dark-gray booking-form-heading"><strong>{t('2_Enter_guest_details')}</strong></h6>
                                    <div className="form-section">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                className="form-control btm-outline" 
                                                placeholder={t('name')} 
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            {errors.name ? (
                                                <div className="error-msg">{errors.name}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                className="form-control btm-outline" 
                                                placeholder={t('email')} 
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            {errors.email ? (
                                                <div className="error-msg">{errors.email}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                className="form-control btm-outline" 
                                                placeholder={t('phone_no')} 
                                                name="phone"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.phone}
                                            />
                                            {errors.phone ? (
                                                <div className="error-msg">{errors.phone}</div>
                                            ) : null}
                                        </div>

                                        <div style={{fontSize:"17px"}} className="form-group">
                                            {costLabel}
                                        </div>
                                        {response_auth_check_user_balance && response_auth_check_user_balance.responseStatus.STATUSCODE == "606" && 
                                            <>
                                                <div className="form-group" style={{color: "red"}}>
                                                    {response_auth_check_user_balance.responseStatus.MESSAGE}
                                                </div>
                                            </>
                                        }
                                        
                                    </div>
                                    {response_auth_check_user_balance && response_auth_check_user_balance.responseData.btn_enable == false ? <button type="button" disabled={true} className="btn btn-solid booking-table-btn">{t('book')}</button>:<button type="submit" className="btn btn-solid booking-table-btn">{t('book')}</button>}
                                </div>
                            </form>
                        </>
                    );
                }}
            />  
        );
    }
}

const mapStateToProps = state => {
    return {
        response_auth_check_user_balance: state.login.response_auth_check_user_balance,
        lang: state.setting.lng,
        post_response_book_table: state.restaurant.post_response_book_table,
        loading: state.restaurant.loading,
        response: state.restaurant.response,
        error: state.restaurant.error,
        loginDetails: state.login.login_details,
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        authCheckUserBalance: payload => dispatch(auth_check_user_balance.request(payload)),
        postBookTableData: payload => dispatch(post_book_table_data.request(payload)),
        responseDismiss: () => dispatch(set_restaurant_state({ post_response_book_table: null })),
        responseUserBalanceDismiss: () => dispatch(set_auth_state({ response_auth_check_user_balance: null })),
    }
}

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(BookTableForm);