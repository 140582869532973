import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
    cart_list, set_order_state, add_to_cart, delete_cart_product
} from "../../redux/reducers/order";
import {page} from "../../redux/reducers/setting";
import { getSessionOrAuthorizedToken, showNotification, CheckForSession, roundOffDecimal } from '../../Helper/Helper'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
    get_restaurant_product_data
} from "../../redux/reducers/restaurant";
import { Scrollbars } from 'react-custom-scrollbars';

class Cart extends Component{
    constructor(props) {
        super(props);
        this.state = {
            lang : props.lang,
            restaurant_id : ''
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.totalPrice = this.totalPrice.bind(this)
    }

    componentDidMount(){
        const { cartList, loginDetails } = this.props;
        let data = getSessionOrAuthorizedToken(loginDetails);
        cartList(data)
    }
    changeHandler(type, product_id, restId, price ,quantity){
        const { t, addToCart  } = this.props;
        let session = CheckForSession();
        
        if(type == "plus"){
            let data = {
                session_id : session,
                restId : restId,
                product_id : product_id,
                price : price,
                quantity : 1
            }
            addToCart(data)
        }
        if(type == "minus"){
            if(parseInt(quantity) > 1){
                let data = {
                    session_id : session,
                    restId : restId,
                    product_id : product_id,
                    price : price,
                    quantity : -1
                }
                addToCart(data)
            }
        }

    }
    deleteProduct(id){
        const { deleteProductCart , t } = this.props;
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui shadow_box confirmation-popup'>
                  <h4>{t("are_you_sure")}</h4>
                  <p className="text-center">{t("want_to_remove_this_product")}</p>
                  <div className="pop-btn-wrap">
                  <button className="btn btn-rounded-yellow btn-sm" onClick={onClose}>{t("no")}</button>
                  <button className="btn btn-rounded-yellow btn-sm"
                    onClick={() => {
                    //   this.handleClickDelete();
                        deleteProductCart({order_detail_id:id})
                        onClose();
                    }}
                  >
                    {t("yes_remove_it")}
                  </button>
                  </div>
                </div>
              );
            }
        }); 
    }

    checkout = () => {
        const { page , loginDetails, history } = this.props
        console.log(loginDetails);
        if(loginDetails){
            history.push(`/checkout`);
        }else{
            page("checkout");
            history.push(`/login`);
        }
        
    }

    totalPrice(count){
        const { t } = this.props
        if(count > 1){
            var ret = count +" "+ t("articles");
            
        }else{
            var ret = count +" "+ t("article")
        }
        return ret
        
    }

    render() {
        const { t, getRestaurantProductData, cartList, cart_list_data , lang, loadingOrder, add_to_cart, responseDismiss, 
            loadingDeleteProduct, responseDeleteDismiss, delete_cart_product_response, loginDetails,restId } = this.props;
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                let data = getSessionOrAuthorizedToken(loginDetails);
                cartList(data)
            })
        }
		if(add_to_cart && add_to_cart.responseStatus.STATUS == "SUCCESS"){
            // showNotification(t("success"),t("added_to_cart"),'success');
            responseDismiss();
            let data = getSessionOrAuthorizedToken(loginDetails);
            getRestaurantProductData({id:restId,orderId:add_to_cart.responseData.order_id});
            cartList(data)
          }
            
          if(add_to_cart && add_to_cart.responseStatus.STATUS == "FAILED"){
            showNotification(t("error"),add_to_cart.responseStatus.MESSAGE,'danger');
            responseDismiss();
          }
          
          if(delete_cart_product_response && delete_cart_product_response.responseStatus.STATUS == "SUCCESS"){
              
            // showNotification(t("success"),delete_cart_product_response.responseStatus.MESSAGE,'success');
            responseDeleteDismiss();
            let data = getSessionOrAuthorizedToken(loginDetails);
            getRestaurantProductData({id:restId});
            cartList(data)
          }
          

        return(
            <>
                <div className="col-lg-4">         
                    <div className="right-cart-panel StickyElementAfterScroll_withfixheight cartbox-closed">
                        <a href="#" className="suare-btn cartbox-close-btn"><i className="fa fa-angle-up" aria-hidden="true"></i></a>
                        <div className="shadow-box">
                            <div className="crtbx-content-wrapr crtbox-heading-area">
                                <h5 className="crtbox-heading"><div dangerouslySetInnerHTML={{ __html: t("your_order") }} /></h5>
                                {cart_list_data && cart_list_data.responseStatus.STATUS == "SUCCESS" &&
                                    <h6>{t('restaurant')}: {cart_list_data.responseData.orderDetails[0].restaurant_name}</h6>
                                }
                            </div>

                            {cart_list_data && cart_list_data.responseStatus.STATUS == "SUCCESS" &&
                            <>
                                <div className="crtitem-scrollcontentarea">
                                    <div className="content mCustomScrollbar">
                                        <Scrollbars style={{ height: 180 }}>
                                        <ul className="crtitem-added-list">
                                        {cart_list_data.responseData.orderDetails.map((val) => (
                                            <li key={val.order_detail_id}>
                                                <div className="crtitem-added-box">
                                                    <h5>{val.product_name}</h5>
                                                    <div className="action-area">
                                                        <div className="incrsDcrsInput">
                                                            {loadingOrder ?
                                                            <a className="eventBtn dcrsBtn disable-link" title="dcrsBtn"></a> : 
                                                            val.product_quantity > 1?
                                                            <a className="eventBtn dcrsBtn" title="dcrsBtn" onClick={() => this.changeHandler('minus',val.product_id,val.restaurant_id, val.product_price ,val.product_quantity)}></a> : 
                                                            <a className="eventBtn dcrsBtn" title="dcrsBtn" onClick={() => this.deleteProduct(val.order_detail_id)}></a>
                                                            }
                                                            <input type="text" value={val.product_quantity} readOnly maxLength="3" className="incrsDecrsValue"  />
                                                            {loadingOrder ?
                                                            <a className="eventBtn incrsBtn disable-link" title="incrsBtn"></a> : 
                                                            <a className="eventBtn incrsBtn" title="incrsBtn" onClick={() => this.changeHandler('plus',val.product_id,val.restaurant_id, val.product_price ,val.product_quantity)}></a>}
                                                              


                                                        </div>
                                                        <div className="crtitem-prize-box"><span className="al_r">{(val.product_price*val.product_quantity).toFixed(2)} €</span></div>
                                                    </div>
                                                </div>
                                            </li>
                                            
                                        ))}
                                        </ul>
                                        </Scrollbars>
                                    </div>
                                </div>

                                <div className="cartitem-subtotal-box">
                                    <div className="subtotal-row">
                                        <span>Subtotal</span>
                                        <span className="float-right">{roundOffDecimal(parseFloat(cart_list_data.responseData.total_price)).toFixed(2)} €</span>
                                    </div>
                                    <a onClick={this.checkout} className="btn btn-solid btn-block">CONTINUE</a>
                                </div>
                            </>
                            }
                            {(!cart_list_data || cart_list_data.responseStatus.STATUS == "FAILED") &&  
                                <div className="crtitem-scrollcontentarea">
                                    <div className="content mCustomScrollbar">
                                        <ul className="crtitem-added-list">
                                            <li><h6 style={{textAlign: "center"}}>{t("no_item_found")}</h6></li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        cart_list_data: state.order.cart_list_data,
        loadingOrder: state.order.loading,
        loadingDeleteProduct: state.order.loadingDeleteProduct,
        delete_cart_product_response: state.order.delete_cart_product_response,
        loginDetails: state.login.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getRestaurantProductData: payload => dispatch(get_restaurant_product_data.request(payload)),
        deleteProductCart: payload => dispatch(delete_cart_product.request(payload)),
        addToCart: payload => dispatch(add_to_cart.request(payload)),
        cartList: payload => dispatch(cart_list.request(payload)),
        responseDismiss: () => dispatch(set_order_state({ add_to_cart: null })),
        responseDeleteDismiss: () => dispatch(set_order_state({ delete_cart_product_response: null })),
        page: payload => dispatch(page(payload)),
    }
  }

    const enhance = compose(
        withRouter,
        withNamespaces(),
        connect(
        mapStateToProps,
        mapDispatchToProps
        )
    );

export default enhance(Cart);