import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SearchBanner from '../SearchBanner/SearchBanner';



class Faq extends Component {
    
    constructor(props) {
        super(props);
    }

    
    render() { 
        
        const { t } = this.props;
        return ( 
            <>
            <Header />
            <SearchBanner />
            <div className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="breadcrumb-ul-list">
                                <li><Link to="/"><i className="fa fa-home" aria-hidden="true"></i></Link></li>
                                <li><span>FAQ</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section-block">
              <div className="container">
                <div className="row faq_content"> 
                  <div className="col-lg-12">
                    <div className="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                      <div className="card shadow-box">
                        <div className="card-header" role="tab" id="headingOne1">
                          <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1" className="collapsed">
                            <h5 className="mb-0">
                              Find the best restaurants in PARIS <i className="fa fa-angle-down rotate-icon"></i>
                            </h5>
                          </a>
                        </div>
                        <div id="collapseOne1" className="collapse show" role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx">
                          <div className="card-body">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                            labore sustainable VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card shadow-box">
                        <div className="card-header" role="tab" id="headingTwo2">
                          <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                            <h5 className="mb-0">
                              Featured Restaurants In Paris <i className="fa fa-angle-down rotate-icon"></i>
                            </h5>
                          </a>
                        </div>
                        <div id="collapseTwo2" className="collapse" role="tabpanel" aria-labelledby="headingTwo2" data-parent="#accordionEx">
                          <div className="card-body">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                            labore sustainable VHS.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Footer />
            </>
        );
    }
}



 
export default withNamespaces()(Faq);