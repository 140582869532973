import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBanner from '../SearchBanner/SearchBanner';
import GoogleMap from '../GoogleMap/GoogleMap';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Slider from "react-slick";
import "react-datepicker/dist/react-datepicker.css";
import {
    get_restaurant_details_data
} from "../../redux/reducers/restaurant";
import BookTableForm from './BookTableForm/BookTableForm';

import {
    post_book_table_data
} from "../../redux/reducers/restaurant";
import { addLoginDetails } from '../../Helper/Helper'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
}
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      />
    );
}



class BookTable extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data : [],
            lang : props.lang,
            restId : null,
            startDate: new Date()
        }
    }

    handleDateChange = date => {
        this.setState({
          startDate: date
        });
    };

    componentDidMount(){
        const { getRestaurantDetailsData,history} = this.props;
        

        const { id } = this.props.match.params
        this.setState({ restId: id },() => {
            getRestaurantDetailsData({id:this.state.restId});
        });

        let loggedDetails = addLoginDetails();
        let authorized = false;
        if(loggedDetails){
          authorized = loggedDetails.session_id ? true : false;
        } 
        if(!authorized){
            history.push(`/login`);
        }   
    }
    
    render() { 
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            
        };

        const { t, lang, restaurant_details_data, getRestaurantDetailsData } = this.props;
        if(lang != this.state.lang){
        	this.setState({
				lang : lang,
				page : 0
        	},()=>{
        		getRestaurantDetailsData({id:this.state.restId});
        	});

        }
                
        // const [startDate, setStartDate] = useState(new Date());
        return ( 
            <>
            <Header />
            <SearchBanner />

	            <div className="breadcrumb-section">
	                <div className="container">
	                    <div className="row">
	                        <div className="col-lg-12">
	                            <ul className="breadcrumb-ul-list">
	                                <li><Link to="/"><i className="fa fa-home" aria-hidden="true"></i></Link></li>
                                    <li><span>{t("book_a_table")}</span></li>
	                            </ul>
	                        </div>
	                    </div>
	                </div>
	            </div>
	            
	            

                <section className="section-block">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-12">
                                <div className="shadow-box book-a-table-box">
                                    <div className="row">

                                        <div className="col-md-7">
                                            <div className="book-table-info-container">
                                                {restaurant_details_data && restaurant_details_data.responseStatus.STATUS == "SUCCESS" && 
                                                    <>
                                                    <h1 className="heading-h4">{restaurant_details_data.responseData.restaurantName}</h1>
                                                    <hr/>
                                                    <div className="book-table-info">
                                                            <p className="dark-gray">{restaurant_details_data.responseData.restaurantAddress}</p>
                                                            <p>{t("cuisine")} : {restaurant_details_data.responseData.restaurantCuisine}</p>
                                                            <p>{t("hours")} : {restaurant_details_data.responseData.restaurantSchedule}</p>
                                                            <p className="margintop-10">{restaurant_details_data.responseData.restaurantDescription}</p>                                         
                                                        
                                                    </div>
                                                    </>
                                                }

                                                
                                                {this.state.restId && restaurant_details_data && restaurant_details_data.responseStatus.STATUS == "SUCCESS" && 
                                                    <BookTableForm restId = {this.state.restId} restCost = {restaurant_details_data.responseData.restaurantCost} />
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-5">
                                            <div className="slider book-a-table-slider">
                                                <Slider {...settings}>
                                                    {restaurant_details_data && restaurant_details_data.responseStatus.STATUS == "SUCCESS" && restaurant_details_data.responseData.restaurantGallaries.map((galImg) => (
                                                        <div key={galImg}><img src={galImg} alt="" /></div>
                                                    ))}
                                                    {/* <div><img src={window.location.origin+"/assets/images/book-table-image-1.jpg"} alt="" /></div> */}
                                                </Slider>
                                            </div>
                                            <div className="shadow-box book-map-area">
                                                <GoogleMap restaurant_details_data={restaurant_details_data} />
                                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.83901258118!2d2.3713924323276245!3d48.861280175902145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66dfc30c37ce9%3A0x19977e019bb1eb24!2sPatchanka!5e0!3m2!1sen!2sin!4v1582019067095!5m2!1sen!2sin" width="600" height="450" frameBorder="0" style={{border:"0"}} allowFullScreen=""></iframe> */}
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>





                
            <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        restaurant_details_data: state.restaurant.get_response_restaurant_details,
        post_response_book_table: state.restaurant.post_response_book_table,
        loading: state.restaurant.loading,
        response: state.restaurant.response,
        error: state.restaurant.error, 
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getRestaurantDetailsData: payload => dispatch(get_restaurant_details_data.request(payload)),
        postBookTableData: payload => dispatch(post_book_table_data.request(payload)),
    }
  }

    const enhance = compose(
        withRouter,
        withNamespaces(),
        connect(
        mapStateToProps,
        mapDispatchToProps,
        
        )
    );

export default enhance (BookTable);

  
  




