import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect,withRouter } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBanner from '../SearchBanner/SearchBanner';
import {
    post_ambassador_data, set_ambassador_state
} from "../../redux/reducers/ambassador";
import axios, { post } from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import "./Ambassador.css"
import AmbassadorForm from './AmbassadorForm/AmbassadorForm'
import { addLoginDetails } from '../../Helper/Helper'

class Ambassador extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            first_name:'',
            last_name:'',
            contact_no:'',
            email:'',
            restaurant_name:'',
            address:'',
            country:'',
            city:'',
            zipcode:'',
            restaurent_contact_no:'',
            file:null,
            remarks:'',
            fileMsg:''
        }

        this.formData = new FormData();
        this.myRef = React.createRef()
        // this.validator = new SimpleReactValidator();
        const { t } = this.props;
        this.validator = new SimpleReactValidator({
          messages: {
            email: t("invalid_email"),
            numeric: t("invalid_contact"),
            // OR
            default: t("required_field")  // will override all messages
          },
        })
        /*this.validator = new SimpleReactValidator({
            validators: {
              email: {  // name the rule
                message: 'dfgdfg',
                rule: (val, params, validator) => {
                  return validator.helpers.testRegex(val,/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/) && params.indexOf(val) === -1
                },
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                required: true  // optional
              }
            }
        });*/




        this.files = '';
        this.onFileChange = this.onFileChange.bind(this);
        this.onChange     = this.onChange.bind(this);
        this.fileUpload   = this.fileUpload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        const {history} = this.props;
        let loggedDetails = addLoginDetails();
        let authorized = false;
        if(loggedDetails){
          authorized = loggedDetails.session_id ? true : false;
        } 
        if(!authorized){
            history.push(`/login`);
        }        
    }
    onFileChange(e) {
        this.setState({file:e.target.files[0]})
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    fileUpload(event){
        // const url = '/var/www/html/angelerfood/food-back/public/uploads/ambassadorImage';
        // const formData = new FormData();
        // Object.keys(event.target.files).map((val)=>{
        this.files = event.target.files 
        // })
        // this.formData.append('file',file)
        // const config = {
        //     headers: {
        //         'content-type': 'multipart/form-data'
        //     }
        // }
        // return  post(url, formData,config)
    }

    handleSubmit(event) {
        const { t } = this.props;
        event.preventDefault();
        if (this.validator.allValid()) {
            if(!this.files){
                this.setState({
                    fileMsg : t("required_field") 
                });
                return false;
            }else{
                this.setState({
                    fileMsg : ''
                });
            }
            
            // alert('Your favorite flavor is: ' + this.state.first_name);
            
            /*this.fileUpload(this.state.file).then((response)=>{
            })*/
            this.formData.append('first_name', this.state.first_name)
            this.formData.append('last_name', this.state.last_name)
            this.formData.append('contact_no', this.state.contact_no)
            this.formData.append('email', this.state.email)
            this.formData.append('restaurant_name', this.state.restaurant_name)
            this.formData.append('address', this.state.address)
            this.formData.append('country', this.state.country)
            this.formData.append('city', this.state.city)
            this.formData.append('zipcode', this.state.zipcode)
            this.formData.append('restaurent_contact_no', this.state.restaurent_contact_no)
            this.formData.append('remarks', this.state.remarks)
            //  = {
            //     first_name: this.state.first_name,
            //     last_name: this.state.last_name,
            //     contact_no: this.state.contact_no,
            //     email: this.state.email,
            //     restaurant_name: this.state.restaurant_name,
            //     address: this.state.address,
            //     country: this.state.country,
            //     city: this.state.city,
            //     zipcode: this.state.zipcode,
            //     restaurent_contact_no: this.state.restaurent_contact_no,
            //     remarks: this.state.remarks,
            // }
            Object.keys(this.files).map((val)=>{
                this.formData.append('file[]',this.files[val])
            })
            this.props.postAmbassadorData({formData:this.formData});
            window.scrollTo(0, this.myRef.current.offsetTop)
            this.setState({
                first_name : '',
                last_name : '',
                contact_no : '',
                email : '',
                restaurant_name : '',
                address : '',
                country : '',
                city : '',
                zipcode : '',
                restaurent_contact_no : '',
                file : '',
                remarks : '',
            });
            this.fileInput.value = ""
        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() { 
        const { 
            first_name,
            last_name,
            contact_no,
            email,
            restaurant_name,
            address,
            country,
            city,
            zipcode,
            restaurent_contact_no,
            file,
            remarks 
        } = this.state;
        const { t, post_response_ambassador, responseDismiss } = this.props;
        if(post_response_ambassador && post_response_ambassador.responseStatus.STATUS == "SUCCESS"){
			responseDismiss();
            return <Redirect to='/ambassador-thank-you' />;
        }
        return ( 
            <>
            <Header />
            <SearchBanner />
            <div className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/*<ul className="breadcrumb-ul-list">
                                <li><a href="#"><i className="fa fa-home" aria-hidden="true"></i></a></li>
                                <li><span>{t("become_an_ambassador")}</span></li>
                            </ul>*/}
                        </div>
                    </div>
                </div>
            </div>
            <section className="section-block">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-8">
                            <div className="form-infobox shadow-box">
                                <div className="info-content">
                                    <h4>{t("become_an_ambassador_header")}</h4>
                                </div>
                                <AmbassadorForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        post_response_ambassador: state.ambassador.post_response_ambassador,
        loading: state.ambassador.loading,
        response: state.ambassador.response,
        error: state.ambassador.error,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        postAmbassadorData: payload => dispatch(post_ambassador_data.request(payload)),
		responseDismiss: () => dispatch(set_ambassador_state({ post_response_ambassador: null })),
    }
  }

const enhance = compose(
    withNamespaces(),
    withRouter,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);
 
export default enhance(Ambassador);