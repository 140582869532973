import React, { Component } from 'react';
import { withNamespaces ,Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import SearchBanner from '../SearchBanner/SearchBanner';
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() { 
        const { t } = this.props;
        return ( 
            <>
            <Header />
            <SearchBanner />

            <div className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="breadcrumb-ul-list">
                                <li><Link to="/"><i className="fa fa-home" aria-hidden="true"></i></Link></li>
                                <li><span>{t('breadcrumb_reset_password')}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section-block">
                <div className="container">
                    <div className="row"> 
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                            <div className="shadow-box loginp_wrap">
                                <div className="loginp_wrap_textco"><h2>{t('breadcrumb_reset_password')}</h2></div>
                                <div className="loginp_innercont">
                                    <ResetPasswordForm />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>  
                </div>
            </section>


            <Footer />
            </>
         );
    }
}
 
export default withNamespaces()(ResetPassword);