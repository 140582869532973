import api from "api";

import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    post_contact_data
} from "../reducers/contact_us";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";

function* postContactUsData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  console.log(payload);
  try {
    const res = yield api.post("/contact-us-submit",payload, {
		
        headers: { "x-lang": lang }
    });
    yield put(post_contact_data.success({ post_response_contact_data: res.data }));
  } catch (err) {
    yield put(post_contact_data.failure({ error: formatError(err) }));
  }
}

function* watchPostContactUsData(){
  yield takeLatest(types.POST_CONTACT_DATA.REQUEST, postContactUsData);
}

export default function* (){
    yield all([
      watchPostContactUsData()
    ]);
};