import api from "api";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    get_top_bakery_list_data
} from "../reducers/bakery";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";

function* getTopBakeryListData(){
  const lang = localStorage.getItem("i18nextLng");

  try {
    const res = yield api.get("/topBakeryLists/", {
      headers: { "x-lang": lang }
    });

    yield put(get_top_bakery_list_data.success({ bakery_data: res.data }));
  } catch (err) {
    yield put(get_top_bakery_list_data.failure({ error: formatError(err) }));
  }
}

function* watchGetTopBakeryListData(){
  yield takeLatest(types.GET_TOP_BAKERY_LIST_DATA.REQUEST, getTopBakeryListData);
}

export default function* (){
    yield all([
      watchGetTopBakeryListData()
    ]);
};