import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SearchBanner from '../SearchBanner/SearchBanner';
import "./Aboutus.css"



class Aboutus extends Component {
    
    constructor(props) {
        super(props);
    }

    
    render() { 
        
        const { t } = this.props;
        return ( 
            <>
            <Header />
            <SearchBanner />
            <div className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="breadcrumb-ul-list">
                                <li><Link to="/"><i className="fa fa-home" aria-hidden="true"></i></Link></li>
                                <li><span>{t("breadcrumb_aboutus")}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section-block">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="shadow-box about_wrap">
                                <h1 className="heading-h4">{t('breadcrumb_aboutus')}</h1>
                                <hr/>
                                <div className="row about_wrap_inner">
                                    <div className="col-lg-4 about_img_cont">
                                        <img src="assets/images/book-table-image-1.jpg" alt=""/>
                                    </div>
                                    <div className="col-lg-8 about_text_container">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                                    </div>
                                </div>
                                <div className="row about_wrap_inner">
                                    <div className="col-lg-8 about_text_container"> 
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                                    </div>
                                    <div className="col-lg-4 about_img_cont">
                                        <img src="assets/images/book-table-image-1.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            </>
        );
    }
}



 
export default withNamespaces()(Aboutus);