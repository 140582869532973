export const types = {
    CHANGE_LANG : "CHANGE_LANG",
    PAGE : "PAGE",
	REMEMBERME : "REMEMBERME",
	PRICE : "PRICE"
}

export const changeLang = (lng) => {
    return{
        type: types.CHANGE_LANG,
        lang: lng
    }
}

export const page = val => ({
  type: types.PAGE,
  page: val
});

export const changePrice = val => ({
  type: types.PRICE,
  price: val
});

export const rememberme = val => ({
  type: types.REMEMBERME,
  rememberme: val
});

const initialState = {
    lng: window.localStorage.getItem("i18nextLng"),
    page : null,
	rememberme: null,
	price : 0
}

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_LANG: return {
      ...state,
      lng:action.lang
    }
    case types.PAGE: return {
      ...state,
      page:action.page
    };
	
	case types.REMEMBERME: return {
      ...state,
      rememberme:action.rememberme
    };
	
	case types.PRICE: return {
      ...state,
      price:action.price
    };

    default: return state
  }
}

export default settingReducer;