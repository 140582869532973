import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
  GET_TOP_BAKERY_LIST_DATA: createAsyncTypes("GET_TOP_BAKERY_LIST_DATA"),
};

export const get_top_bakery_list_data = createAsyncActions("GET_TOP_BAKERY_LIST_DATA");

const initialState = {
    bakery_data: null,
    loading: null,
    response: null,
    error: null
};

const bakery = (state = initialState, action) => {
    switch (action.type) {  
      case types.GET_TOP_BAKERY_LIST_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_TOP_BAKERY_LIST_DATA.SUCCESS:
        return {
          ...state,
          bakery_data: action.payload.bakery_data,
          loading: null
        };
      case types.GET_TOP_BAKERY_LIST_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      default:
            return state;
      }
};

export default bakery;