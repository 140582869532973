import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Formik } from "formik";
import * as Yup from 'yup';
import { Redirect } from 'react-router-dom';
import FocusError from "../../Utility/FocusError" 
import { showNotification, getRememberToken } from '../../../Helper/Helper';
import MD5 from 'crypto-js/md5';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { rememberme } from '../../../redux/reducers/setting'
import {
    auth_check_user_data,post_user_login_data, set_auth_state, remember_me
} from "../../../redux/reducers/login";

class LoginForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            lang : props.lang,
            sessionId : null,
			rememberme : null
        }
    }
    render(){
        const { t,lang,rememberme,response_auth_check_user_data, openModal, responseDismiss ,authCheckUserData, get_response_user_login_data, postUserLoginData, responseUserDismiss, rememberMeApi, remember_me_response } = this.props;
        if(lang != this.state.lang){
            this.setState({
                lang : lang,
            },()=>{
                this.resetForm();
            });
        }

        if(lang == 'en'){
            var language = 'english';
        }else{
            var language = 'french';
        }

		console.log(remember_me_response,'fsfsfd');
        var HASHKEY = "secret key 123";
        if(!remember_me_response){
            rememberMeApi({remember_me_token :getRememberToken() })
        }       
        
        const initialValues = {
            email: (remember_me_response && remember_me_response.responseStatus.STATUS == "SUCCESS") ? remember_me_response.responseData.email : '',
            password : (remember_me_response && remember_me_response.responseStatus.STATUS == "SUCCESS") ? remember_me_response.responseData.password : '',
            remember_me : (remember_me_response && remember_me_response.responseStatus.STATUS == "SUCCESS") ? true : false,
			lang:language,
            flag:'Web'
        };

        console.log(initialValues)
        
        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email(t("invalid_email"))
                .required(t('required_field')),
            password: Yup.string()
                .required(t('required_field')),
        });

        return (
            <Formik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values, form) => {
                    values.password = Base64.stringify(hmacSHA256(MD5(values.password).toString(),HASHKEY));
                    const { setSubmitting } = form;
					//console.log(values.remember_me)
					rememberme(values.remember_me)
                    authCheckUserData(values)
                }}>

                {formikProps => {
                    this.resetForm = formikProps.resetForm
                    const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                    } = formikProps;
                
                    return(
                        <>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <div>
                                            <input 
                                                type="text" 
                                                className="form-control btm-outline" 
                                                placeholder={t('email')} 
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            {errors.email ? (
                                                <div className="error-msg">{errors.email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <div>
                                            <input 
                                                type="password" 
                                                className="form-control btm-outline" 
                                                placeholder={t('login_password')} 
                                                name="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                            {errors.password ? (
                                                <div className="error-msg">{errors.password}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 loginp_checkbtn">        
                                  <input type="checkbox" 
                                    name="remember_me"
                                    onChange={handleChange}
                                    value="1"
                                    checked={values.remember_me}
                                    /><span className="chk_icon"></span>{t("remember_me")}             
                                </div>
                                {/*<div className="col-lg-6 fr_textmod">
                                  <a onClick={() => openModal('forgotPasswordModal')}>{t("forgot_password")}</a>     
                                </div>*/}
                            </div>
                            <div className="row">
                                <div className="col-lg-12 loginp_ac"> 
                                    <button type="submit" className="btn btn-solid col-md-3 checkout_gapbtn">{t('login_button')}</button>
										{/*<p className="loginp_signtext">{t('no_account')} <Link to="/register">{t('signup')}</Link></p>*/}
                                </div>
                            </div>                            
                        </form>
                        </>
                    );
                }}
            </Formik>
        );
    }
}
const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
		response_auth_check_user_data: state.login.response_auth_check_user_data,
        get_response_user_login_data: state.login.get_response_user_login_data,
        remember_me_response: state.login.remember_me_response,
        loading: state.login.loading,
        response: state.login.response,
        error: state.login.error,
	}
  }
  
const mapDispatchToProps = dispatch => {
	return {
		authCheckUserData: payload => dispatch(auth_check_user_data.request(payload)),
		postUserLoginData: payload => dispatch(post_user_login_data.request(payload)),
        responseUserDismiss: () => dispatch(set_auth_state({ get_response_user_login_data: null })),
        rememberMeApi: payload => dispatch(remember_me.request(payload)),
        responseRememberMeResponse: () => dispatch(set_auth_state({ remember_me_response: null })),
		rememberme: payload => dispatch(rememberme(payload)),
    }
}

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(LoginForm);
