import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import i18n from "i18n";
import { compose } from "recompose";
import { connect } from 'react-redux'
import { changeLang,page } from '../../redux/reducers/setting'
import { BrowserRouter as Router, Switch, Route, Link , withRouter} from "react-router-dom";
import ResponsiveMenu from 'react-responsive-navbar';
import "./Header.css"
import { getSessionOrAuthorizedToken, addLoginDetails, removeLoginDetails, showNotification } from '../../Helper/Helper'
import { cart_list } from "../../redux/reducers/order";
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import queryString from 'query-string'
import {
    auth_check_user_data,post_user_login_data, set_auth_state, post_user_logout_data
} from "../../redux/reducers/login";
import CookieConsent from "react-cookie-consent";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showLangList : false,
            lang : this.props.language,
            page : "",
            stateLoggedDetails : 'loading'
         }
        this.toggeleLangDrop = this.toggeleLangDrop.bind(this);
        this.langSelectedHandler = this.langSelectedHandler.bind(this);
    }
    langSelectedHandler(lang) {
        const { language, changeLang } = this.props;
        i18n.changeLanguage(lang);
        this.setState({
            showLangList : false
        })
        changeLang(lang)
      }

    toggeleLangDrop(){
        if(this.state.showLangList){
            this.setState({
                showLangList : false
            })
        }else{
            this.setState({
                showLangList : true
            })
        }
        
    }

    componentDidMount(){
        const { authCheckUserData , history} = this.props;
        let url = window.location.href;
        let restaurantUrl = url.indexOf("restaurant");
        let aboutusUrl = url.indexOf("about-us");
        let loginUrl = url.indexOf("login");
        let faqUrl = url.indexOf("faq");
        let contactusUrl = url.indexOf("contact-us");
        if(restaurantUrl >= 0){
            this.setState({
                page : "restaurant"
            })
        }else if(loginUrl >= 0){
            this.setState({
                page : "login"
            })
        }else if(aboutusUrl >= 0){
            this.setState({
                page : "about-us"
            })
        }else if(faqUrl >= 0){
            this.setState({
                page : "faq"
            })
        }else if(contactusUrl >= 0){
            this.setState({
                page : "contact-us"
            })
        }else{
            this.setState({
                page : "home"
            })
        }
        const values = queryString.parse(this.props.location.search)
        var token = values.token;
        var email = values.email;
        var HASHKEY = "secret key 123";
        
        if(token && email){
            const angelerId = Base64.stringify(hmacSHA256(token, HASHKEY));
            var loggedDetails = addLoginDetails();
            if(loggedDetails.email != email){
                this.logout(loggedDetails);
                authCheckUserData({angelerId:angelerId,email:email,flag:"App"})
            }else{
                history.push(`/`);
            }
        }
    }

    logout = (loginDetails) => {
        const { page,responseDismiss , history, logout, postUserLogoutData} = this.props;
        // logout({});
        postUserLogoutData({userId:loginDetails.user_id,sessionId:loginDetails.session_id});
        responseDismiss();
        removeLoginDetails();
        page("");
        history.push(`/`);
    }

    render() { 
        const { t,remember_me, cartList,loginDetails , response_auth_check_user_data, responseUserDismiss,responseUserAuthDismiss,
            postUserLoginData, get_response_user_login_data} = this.props;
        if(this.state.stateLoggedDetails != loginDetails){
            this.setState({
                stateLoggedDetails : loginDetails
            },()=>{
                let data = getSessionOrAuthorizedToken(loginDetails);
                cartList(data)
            })
            
        }

        if(response_auth_check_user_data && response_auth_check_user_data.responseStatus.STATUS == "SUCCESS"){
            let angelerResponse = response_auth_check_user_data.responseData;
			angelerResponse.remember_me = remember_me;
            responseUserAuthDismiss();
			//console.log(response_auth_check_user_data.responseData); 
			console.log(angelerResponse)
			
            postUserLoginData(angelerResponse);
        }
            
        if(response_auth_check_user_data && response_auth_check_user_data.responseStatus.STATUS == "FAILED"){
            showNotification(t("error"),response_auth_check_user_data.responseStatus.MESSAGE,'danger','top-left');
            responseUserAuthDismiss();
        }

        if(get_response_user_login_data && get_response_user_login_data.responseStatus.STATUS == "SUCCESS"){
            // showNotification(t("success"),get_response_user_login_data.responseStatus.MESSAGE,'success','top-left');
            this.setState({sessionId:get_response_user_login_data.responseData.session_id});
            responseUserDismiss();
            // return <Redirect to='/' />;
        }
            
        if(get_response_user_login_data && get_response_user_login_data.responseStatus.STATUS == "FAILED"){
            showNotification(t("error"),get_response_user_login_data.responseStatus.MESSAGE,'danger','top-left');
            responseUserDismiss();
        }

        return ( 
            <>
            <div id="preloader" style={{display:"none"}}>{/*{t("home")}*/}</div>
            <CookieConsent
             buttonText={t("i_understand")}
             buttonStyle={{ background: "#009cee",color: "#fff" }}
             >
            {t("cookie_consent")}
            </CookieConsent>
            <header className="blue-gradient-bg">
                <div className="header-strip-container">
                    <div className="header-logo-area">
                    <Link to="/"><img src={window.location.origin+"/assets/images/logo.png"} alt="Angelertrip" /></Link>
                    </div>
                    
                    <div className="header-right-panel">
                    <ResponsiveMenu
                        menuOpenButton={<><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></>}
                        menuCloseButton={<><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></>}
                        changeMenuOn="1023px"
                        largeMenuClassName="large-menu-classname"
                        smallMenuClassName="collapse-button"
                        menu={
                        <ul className="slimmenu">

                            <li className={(this.state.page == 'home' ? 'active' : '')}><Link to="/">{t("home_menu")}</Link> </li>
                            <li className={(this.state.page == 'about-us' ? 'active' : '')}><Link to="/about-us">{t("about_us_menu")}</Link></li>
                            {/*<li className={(this.state.page == 'products' ? 'active' : '')}><Link to="/products/SEFraGVCbm5acEU5dVh2OHJTTzRpQT09/">{t("product_menu")}</Link></li>*/}
                            <li className={(this.state.page == 'restaurant' ? 'active' : '')}><Link to="/restaurant-list/">{t("restaurants_menu")}</Link></li>
                            <li className={(this.state.page == 'contact-us' ? 'active' : '')}><Link to="/contact-us/">{t("contact_us_menu")}</Link></li>


                            {/* {loginDetails ? 
                                <li><a style={{cursor: "pointer"}} onClick={this.logout}>{t("sign_out_menu")}</a></li>
                           response_auth_check_user_data: state.login.response_auth_check_user_data,
        get_response_user_login_data: state.login.get_response_user_login_data, : <li className={(this.state.page == 'login' ? 'active' : '')}><Link to="/login/">{t("sign_in_menu")}</Link></li>
                            } */}
                            {/* <li><a href="#">{t("sign_up_menu")}</a></li> */}


                            <li className={(this.state.page == 'faq' ? 'active' : '')}><Link to="/faq">{t("faq_menu")}</Link></li>
                        </ul>
                        }
                    />

                        {/* <ul className="hdr-login-list">
                            <li><a href="#">{t("sign_in")}</a></li>
                            <li><a href="#">{t("sign_up")}</a></li>
                        </ul>
                        <div className="header_dropdown_wrap">
                            <div className="headerDropdown countryouter" id="">{t("help")}</div>
                            <div className="inercountry" style={{display:"none"}}>
                               
                            </div>
                        </div> */}
                        {/* <a className="cart-link"><img src={window.location.origin+"/assets/images/shopping-cart.svg"} alt=""/><span className="shopping-count">2</span></a> */}
                        <div className="header_dropdown_wrap">
                            <div className="countryouter" id="" onClick={this.toggeleLangDrop}>
                            {this.props.lng == "en" ? t("english") : t("french") }</div>
                            {this.state.showLangList ? <div className="inercountry">
                                <a onClick={() => this.langSelectedHandler("en")}><span>{t("english")}</span></a>
                                <a onClick={() => this.langSelectedHandler("fr")}><span>{t("french")}</span></a>
                            </div> : null}
                        </div>
                        <ul className="header_login_menu">
                            {loginDetails ? 
                            <>
                            <li><Link to="/dashboard/" style={{cursor: "pointer"}}><div className="user_profile_image">
                                <img src={loginDetails.profile_image} alt="" />
                            </div> {loginDetails.first_name}</Link></li>
                            <li><a style={{cursor: "pointer"}} onClick={()=>this.logout(loginDetails)}><i className="fa fa-power-off" aria-hidden="true"></i></a></li>
                            </>
                            :<li><Link to="/login/">{t("sign_in_menu")}</Link></li>}
                        </ul>
                    </div>
                </div>
            </header>
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        response_auth_check_user_data: state.login.response_auth_check_user_data,
        get_response_user_login_data: state.login.get_response_user_login_data,
        get_response_user_logout_data: state.login.get_response_user_logout_data,
        loginDetails: state.login.login_details,
        language: state.setting.lng,
		remember_me: state.setting.rememberme
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        authCheckUserData: payload => dispatch(auth_check_user_data.request(payload)),
        postUserLoginData: payload => dispatch(post_user_login_data.request(payload)),
        postUserLogoutData: payload => dispatch(post_user_logout_data.request(payload)),
        responseUserDismiss: () => dispatch(set_auth_state({ get_response_user_login_data: null })),
        changeLang: (lng) => dispatch(changeLang(lng)),
        responseDismiss: () => dispatch(set_auth_state({ login_details: null })),
        responseUserAuthDismiss: () => dispatch(set_auth_state({ response_auth_check_user_data: null })),
        page: payload => dispatch(page(payload)),
        cartList: payload => dispatch(cart_list.request(payload)),
    }
  }

const enhance = compose(
    withNamespaces(),
    withRouter,
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  );
 
export default enhance(Header);