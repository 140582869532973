import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from 'yup';
import "./RegisterForm.css"
import {
    register, set_auth_state, post_user_login_data
} from "../../../redux/reducers/login";
import { showNotification } from '../../../Helper/Helper'


class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
            email : '',
            password : ''
        };
        this.resetFormData = ''
    }
    render() { 
        const { t , registerData, register, history, responseDismiss, lang, loadingRegister, postUserLoginData, login_response, responseLoginDismiss} = this.props;
        const initialValues = {
            first_name: '',
              last_name: '',
              email: '',
              password : '',
              password_confirmation : '',
              check : ''
          };
        if(this.state.lang != lang){
            this.setState({
                lang : lang
            },()=>{
                this.resetFormData()
            })
        }
        if(register && register.responseStatus.STATUS == "SUCCESS"){
            // showNotification(t("success"),t("successfully_registered"),'success');
            responseDismiss();
            // history.push(`/login`);
            let loginCredentials = {
                email: this.state.email,
                password : this.state.password
            }
            postUserLoginData(loginCredentials);
        }else if(register && register.responseStatus.STATUS == "FAILED"){
            let error_msg = register.responseStatus.MESSAGE.replace(",", ", ");
            showNotification(t("error"),error_msg,'danger');
            responseDismiss();
        }

        if(login_response && login_response.responseStatus.STATUS == "SUCCESS"){
            showNotification(t("success"),t("successfully_logged_in"),'success');
            responseLoginDismiss();
            // history.push(`/login`);
        }else if(login_response && login_response.responseStatus.STATUS == "FAILED"){
            let error_msg = login_response.responseStatus.MESSAGE.replace(",", ", ");
            showNotification(t("error"),error_msg,'danger');
            responseLoginDismiss();
        }
        const validationSchema = Yup.object().shape({
            first_name: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t('required')),
            last_name: Yup.string()
                .max(50, t("must_be_50_chars"))
                .required(t('required')),
            email: Yup.string()
                .email(t('invalid_email_address'))
                .required(t('required')),
            password: Yup.string()
                .min(8, t("password_should_be_8_chars_or_more"))
                .required(t("required")),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref("password"), null], t("password_not_match"))
                .required(t("required")),
            check: Yup.string()
                .required(t('required')),
        });
          
        return ( 
        <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, form) => {
            this.setState({
                email : values.email,
                password : values.password
            })
          registerData(values);
        }}
        render={formikProps => {
            this.resetFormData = formikProps.resetForm
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = formikProps;

          return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input type="text" className="form-control btm-outline" name="first_name" onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("first_name")}
                            value={values.first_name} />
                            {errors.first_name ? (
                                <div className="error-msg">{errors.first_name}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input type="text" className="form-control btm-outline" 
                            name="last_name" onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("last_name")}
                            value={values.last_name} />
                            {errors.last_name ? (
                                <div className="error-msg">{errors.last_name}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <input type="text" className="form-control btm-outline" 
                            name="email" onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("email")}
                            value={values.email} />
                            {errors.email ? (
                                <div className="error-msg">{errors.email}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input type="password" className="form-control btm-outline" 
                            name="password" onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("password")}
                            value={values.password} />
                            {errors.password ? (
                                <div className="error-msg">{errors.password}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <input type="password" className="form-control btm-outline" 
                            name="password_confirmation" onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t("password_confirmation")}
                            value={values.password_confirmation} />
                            {errors.password_confirmation ? (
                                <div className="error-msg">{errors.password_confirmation}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 loginp_checkbtn">
                        <input type="checkbox" name="check" onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.check} />
                        
                        <span className="chk_icon"></span>
                        <Trans i18nKey="agree_to_terms_and_condition">
                            I Agree to the <a href="#">Terms and Conditions</a>
                        </Trans>
                        
                        {errors.check ? (
                            <div className="error-msg">{errors.check}</div>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 loginp_ac"> 
                        {loadingRegister ? 
                            <button className="btn btn-solid col-md-3 checkout_gapbtn disable-link" disabled type="submit">{t("signup")}</button> :
                            <button className="btn btn-solid col-md-3 checkout_gapbtn" type="submit">{t("signup")}</button>}
                            <p className="loginp_signtext">{t("already_have_account")}<Link to="/login">{t("login")}</Link></p>
                    </div>
                </div>
            </form>
          );
        }}
        />
      );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        register: state.login.register,
        loadingRegister: state.login.loadingRegister,
        login_response: state.login.get_response_user_login_data,
        loadingLogin: state.login.loadingLogin,
        response: state.login.response,
        error: state.login.error,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        registerData: payload => dispatch(register.request(payload)),
        responseDismiss: () => dispatch(set_auth_state({ register: null })),
        postUserLoginData: payload => dispatch(post_user_login_data.request(payload)),
        responseLoginDismiss: () => dispatch(set_auth_state({ login_response: null })),
    }
  }

const enhance = compose(
    withRouter,
    withNamespaces(),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
);

export default enhance(RegisterForm);