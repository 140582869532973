import api from "api";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    get_customer_details , edit_profile,
    edit_shipping_address, edit_billing_address,
    change_password, password_regen, reset_password
} from "../reducers/customer";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";

function* getCustomerDetails({payload}){
    const lang = localStorage.getItem("i18nextLng");
    let authorization = ''
    let loggedDetails = yield select(state => state.login.login_details);
    
    if(loggedDetails){
      authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
    }
    
    try {
      const res = yield api.post("/getFullCustomerDetails", payload,{
        headers: { "x-lang": lang , "x-access-token" : authorization }
      });
  
      yield put(get_customer_details.success({ customer_details_response: res.data }));
    } catch (err) {
      yield put(get_customer_details.failure({ error: formatError(err) }));
    }
  }
  
  function* watchGetCustomerDetails(){
    yield takeLatest(types.GET_CUSTOMER_DETAILS.REQUEST, getCustomerDetails);
  }

  function* postEditProfile({payload}){
    const lang = localStorage.getItem("i18nextLng");
    let authorization = ''
    let loggedDetails = yield select(state => state.login.login_details);
    
    if(loggedDetails){
      authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
    }
    try {
      const res = yield api.post("/editProfile", payload,{
        headers: { "x-lang": lang , "x-access-token" : authorization }
      });
  
      yield put(edit_profile.success({ edit_profile_response: res.data }));
    } catch (err) {
      yield put(edit_profile.failure({ error: formatError(err) }));
    }
  }
  
  function* watchPostEditProfile(){
    yield takeLatest(types.EDIT_PROFILE.REQUEST, postEditProfile);
  }

  function* postEditShippingAddress({payload}){
    const lang = localStorage.getItem("i18nextLng");
    let authorization = ''
    let loggedDetails = yield select(state => state.login.login_details);
    
    if(loggedDetails){
      authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
    }
    try {
      const res = yield api.post("/editShippingAddress", payload,{
        headers: { "x-lang": lang , "x-access-token" : authorization }
      });
  
      yield put(edit_shipping_address.success({ edit_shipping_address_response: res.data }));
    } catch (err) {
      yield put(edit_shipping_address.failure({ error: formatError(err) }));
    }
  }
  
  function* watchPostEditShippingAddress(){
    yield takeLatest(types.EDIT_SHIPPING_ADDRESS.REQUEST, postEditShippingAddress);
  }

  function* postEditBillingAddress({payload}){
    const lang = localStorage.getItem("i18nextLng");
    let authorization = ''
    let loggedDetails = yield select(state => state.login.login_details);
    
    if(loggedDetails){
      authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
    }
    try {
      const res = yield api.post("/editBillingAddress", payload,{
        headers: { "x-lang": lang , "x-access-token" : authorization }
      });
  
      yield put(edit_billing_address.success({ edit_billing_address_response: res.data }));
    } catch (err) {
      yield put(edit_billing_address.failure({ error: formatError(err) }));
    }
  }
  
  function* watchPostEditBillingAddress(){
    yield takeLatest(types.EDIT_BILLING_ADDRESS.REQUEST, postEditBillingAddress);
  }

  function* postChangePassword({payload}){
    const lang = localStorage.getItem("i18nextLng");
    let authorization = ''
    let loggedDetails = yield select(state => state.login.login_details);
    
    if(loggedDetails){
      authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
    }
    try {
      const res = yield api.post("/changePassword", payload,{
        headers: { "x-lang": lang , "x-access-token" : authorization }
      });
  
      yield put(change_password.success({ change_password_response: res.data }));
    } catch (err) {
      yield put(change_password.failure({ error: formatError(err) }));
    }
  }
  
  function* watchPostChangePassword(){
    yield takeLatest(types.CHANGE_PASSWORD.REQUEST, postChangePassword);
  }

  function* postPasswordRegen({payload}){
    const lang = localStorage.getItem("i18nextLng");
    try {
      const res = yield api.post("/passwordRegen", payload,{
        headers: { "x-lang": lang }
      });
  
      yield put(password_regen.success({ password_regen_response: res.data }));
    } catch (err) {
      yield put(password_regen.failure({ error: formatError(err) }));
    }
  }
  
  function* watchPostPasswordRegen(){
    yield takeLatest(types.PASSWORD_REGEN.REQUEST, postPasswordRegen);
  }

  function* postResetPassword({payload}){
    const lang = localStorage.getItem("i18nextLng");
    try {
      const res = yield api.post("/resetPassword", payload,{
        headers: { "x-lang": lang }
      });
  
      yield put(reset_password.success({ reset_password_response: res.data }));
    } catch (err) {
      yield put(reset_password.failure({ error: formatError(err) }));
    }
  }
  
  function* watchPostResetPassword(){
    yield takeLatest(types.RESET_PASSWORD.REQUEST, postResetPassword);
  }

  export default function* (){
    yield all([
        watchGetCustomerDetails(),
        watchPostEditProfile(),
        watchPostEditShippingAddress(),
        watchPostEditBillingAddress(),
        watchPostChangePassword(),
        watchPostPasswordRegen(),
        watchPostResetPassword()
    ]);
};