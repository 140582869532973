import React, { Component } from 'react';
import  Header  from "../Header/Header"
import  Footer  from "../Footer/Footer"
import  CheckoutForm  from "./CheckoutForm/CheckoutForm"
import { compose } from "recompose";
import { connect } from "react-redux";
import {
    get_customer_details
} from "../../redux/reducers/customer";
import { getSessionOrAuthorizedToken } from '../../Helper/Helper'
import SearchBanner from '../SearchBanner/SearchBanner';

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            stateLoggedDetails : 'loading'
         }
    }
    
    render() { 
        const { customer_details_response, loginDetails, customerDetails } = this.props;
        if(this.state.stateLoggedDetails != loginDetails){
            this.setState({
                stateLoggedDetails : loginDetails
            },()=>{
                let data = getSessionOrAuthorizedToken(loginDetails);
                customerDetails(data)
            })
            
        }

        console.log(customer_details_response);
        return ( 
            <>
            <Header />
            <SearchBanner />
                <div className="breadcrumb-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="breadcrumb-ul-list">
                                    <li><a href="#"><i className="fa fa-home" aria-hidden="true"></i></a></li>
                                    <li><span>Checkout</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="section-block">
                    <div className="container">
                        {customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS" && <CheckoutForm customerDetail={customer_details_response} />}
                        {(!customer_details_response || (customer_details_response && customer_details_response.responseStatus.STATUS == "FAILED")) && <CheckoutForm customerDetail={customer_details_response} />}
                    </div>
                </section>
            <Footer />
            </>
         );
    }
}

const mapStateToProps = state => {
    return {
        customer_details_response: state.customer.customer_details_response,
        loginDetails: state.login.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        customerDetails: payload => dispatch(get_customer_details.request(payload)),
    }
  }

const enhance = compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(Checkout);