import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBanner from '../SearchBanner/SearchBanner';
import "./Thankyou.css"



class Thankyou extends Component {
    
    constructor(props) {
        super(props);
    }

    
    render() { 
        
        const { t } = this.props;
        return ( 
            <>
            <Header />
            <SearchBanner />
            <div className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/*<ul className="breadcrumb-ul-list">
                                <li><a href="#"><i className="fa fa-home" aria-hidden="true"></i></a></li>
                                <li><span>{t("become_an_ambassador")}</span></li>
                            </ul>*/}
                        </div>
                    </div>
                </div>
            </div>
            <section className="section-block">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-8">
                            <div className="thankyou_content shadow-box">
                                <div className="info-content">
                                    <h4>{t("order_placed_success")}</h4>
                                </div>
                                
                                    <div className="thank_inner">
                                        

                                        
                                        <div className="row">
                                            <div className="alert alert-dark">{t("order_textline")}</div>
                                        </div>
                                        

                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            </>
        );
    }
}



 
export default withNamespaces()(Thankyou);