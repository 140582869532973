import { all } from "redux-saga/effects";
import bakery from "./bakery";
import ambassador from "./ambassador";
import restaurant from "./restaurant";
import featured_restaurant_list from "./featured_restaurant_list";
import order from "./order";
import login from "./login";
import userLogin from "./userLogin";
import customer from "./customer";
import contact_us from "./contact_us";

 function* root() {
  yield all([
    bakery(),
    ambassador(),
    featured_restaurant_list(),
    restaurant(),
    order(),
    login(),
    userLogin(),
    customer(),
	contact_us()
  ]);
}
export default root;