import api from "api";

import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    get_featured_restaurant_data
} from "../reducers/featured_restaurant_list";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";

function* getFeaturedRestaurantData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  try {
    const res = yield api.get("/featured-restaurant-list?restaurantLimit="+payload.restaurantLimit,{
      headers: { "x-lang": lang }
    });
    // yield api.post("/rest-auth/registration/", payload.params, {
    //   "Accept-Language": lang
    // });
    yield put(get_featured_restaurant_data.success({ get_response_featured_restaurant: res.data }));
  } catch (err) {
    yield put(get_featured_restaurant_data.failure({ error: formatError(err) }));
  }
}

function* watchGetFeaturedRestaurantData(){
  yield takeLatest(types.GET_FEATURED_RESTAURANT_DATA.REQUEST, getFeaturedRestaurantData);
}

export default function* (){
    yield all([
      watchGetFeaturedRestaurantData()
    ]);
};