import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBanner from '../SearchBanner/SearchBanner';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Cart from '../Cart/Cart';
import { CheckForSession, showNotification, getSessionOrAuthorizedToken } from '../../Helper/Helper'
import {
    get_restaurant_product_data
} from "../../redux/reducers/restaurant";
import {
    cart_list, set_order_state, add_to_cart, delete_cart_product
} from "../../redux/reducers/order";
import "./Products.css"


class Products extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data : [],
            lang : props.lang,
            restId : null
        }
        this.addToCart = this.addToCart.bind(this)
    }

    componentDidMount(){
        const { getRestaurantProductData} = this.props;
        

        const { id } = this.props.match.params
        this.setState({ restId: id },() => {
            getRestaurantProductData({id:this.state.restId});
        });
        
    }

    addToCart(product_id, price ,quantity,cartList){
        const { t, addToCart } = this.props;
        let session = CheckForSession();
        let data = {
            session_id : session,
            restId : this.state.restId,
            product_id : product_id,
            price : price,
            quantity : quantity
        }
        // console.log(data);
        addToCart(data)
        
    }

    render() { 
        const { t, lang, cart_list_data, product_data, getRestaurantProductData,loginDetails, add_to_cart, cartList, responseDismiss,loadingOrder } = this.props;

		if(add_to_cart && add_to_cart.responseStatus.STATUS == "SUCCESS"){
            //showNotification(t("success"),t("added_to_cart"),'success');
            responseDismiss();
            let data = getSessionOrAuthorizedToken(loginDetails);
            getRestaurantProductData({id:this.state.restId,orderId:add_to_cart.responseData.order_id});
            cartList(data)
          }
            
          if(add_to_cart && add_to_cart.responseStatus.STATUS == "FAILED"){
            showNotification(t("error"),add_to_cart.responseStatus.MESSAGE,'danger');
            responseDismiss();
          }

        if(lang != this.state.lang){
        	this.setState({
				lang : lang,
				page : 0
        	},()=>{
        		getRestaurantProductData({id:this.state.restId});
        	});

        }
        let runningCategoryName = "";
        // let newCategoryName = "";
        
        return ( 
            <>
            <Header />
            <SearchBanner />

	            <div className="breadcrumb-section">
	                <div className="container">
	                    <div className="row">
	                        <div className="col-lg-12">
	                            <ul className="breadcrumb-ul-list">
	                                <li><Link to="/"><i className="fa fa-home" aria-hidden="true"></i></Link></li>
                                    <li><span>{t('order_online_new')}</span></li>
	                            </ul>
	                        </div>
	                    </div>
	                </div>
	            </div>
	            
	            <section className="section-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="book-table-infobox shadow-box">
                                    {product_data && product_data.responseStatus.STATUS == "SUCCESS" && 
                                    <>
                                    <div className="info-content">
                                        <h5>{t('order_food_online_from')}</h5>
                                            <h4>{product_data.responseData.restaurantName}</h4>
                                            <p>{product_data.responseData.restaurantAddress}</p>
                                            {/*<p>{t("cuisine")} : {product_data.responseData.restaurantCuisine}</p>*/}
                                    </div>
                                    <div className="book-action-panel">
                                        <div className="panel-content-wrap">
                                            <ul className="book-time-info-list">
                                                <li><img src="assets/images/fast-time.png" alt="" /><span><b>{t('delivery_time')} :</b> {t('delivery_time_value')}</span></li>
                                                {/*<li><img src="assets/images/order.png" alt="" /><span><b>{t('minimum_order')} :</b> €85</span></li>*/}
                                            </ul>
                                            {/*
                                            { product_data.responseData.bookTable && product_data.responseData.bookTable == 'Y' && 
                                            <Link to={`/book-table/${product_data.responseData.restaurantId}`} className="btn btn-solid">{t('book_a_table_btn')}</Link>
                                            } */}
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">

                                {/*<div className="checkout_categoryHeading">
                                <h2>fruits</h2>
                                </div>*/}

                                <ul className="product-add-list">
                                    {product_data && product_data.responseStatus.STATUS == "SUCCESS" && product_data.responseData.data.length > 0 && product_data.responseData.data.map((val) => (
                                        <li key={val.id}>

                                            {runningCategoryName != val.category &&
                                                <>
                                                <div className="checkout_categoryHeading">
                                                <h2>{runningCategoryName = val.category}</h2>
                                                </div>
                                                </>
                                            }

                                            <div className="prdct-row-viewbox">
                                                <div className="product-img-frame"><img src={val.image} alt={val.name} /></div>
                                                <div className="product-info-area">
                                                    <div className="product-info-col">
                                                        <h3><a href="#">{val.name}</a></h3>
                                                        <div className="pirce-display">€ {val.price}</div>
                                                        <p className="elips-para">{val.description}</p>
                                                    </div>
                                                    <div className="product-actn-col">
                                                     {cart_list_data && cart_list_data.responseStatus.STATUS == "SUCCESS" 
                                                    && cart_list_data.responseData.orderDetails.find(({product_id}) => product_id == val.id) ?
                                                        <a disabled className="btn btn-solid btn-gray disable-link">{t('added')}</a>
                                                         :  loadingOrder ?
                                                         <a className="btn btn-solid btn-gray disable-link">{t('add')}</a> : 
                                                        <a onClick={() => this.addToCart(val.id,val.price,1)}
                                                         className="btn btn-solid btn-gray">{t('add')}</a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                    {product_data && product_data.responseStatus.STATUS == "SUCCESS" && product_data.responseData.data.length === 0 &&
                                        <div className="col-md-12" style={{textAlign:"center"}} ><h4>{t("product_not_found")}</h4></div>
                                    }
                                    {product_data && product_data.responseStatus.STATUS == "FAILED" &&
                                        <div className="col-md-12" style={{textAlign:"center"}} ><h4>{t("product_not_found")}</h4></div>
                                    }
                                </ul>
                            </div>
                            <Cart restId = {this.state.restId}/>
                            {/*<div className="col-lg-4">
                                
                                <div className="right-cart-panel StickyElementAfterScroll_withfixheight cartbox-closed">
                                    <a href="#" className="suare-btn cartbox-close-btn"><i className="fa fa-angle-up" aria-hidden="true"></i></a>
                                    <div className="shadow-box">
                                        <div className="crtbx-content-wrapr crtbox-heading-area">
                                            <h5 className="crtbox-heading">Your <strong>Order</strong></h5>
                                </div>*/}
                                        
                                        {/* <div className="crtitem-scrollcontentarea">
                                            <div className="content mCustomScrollbar">
                                                
                                                <ul className="crtitem-added-list">
                                                    <li>
                                                        <div className="crtitem-added-box">
                                                            <h5>Roasted chicken</h5>
                                                            <div className="action-area">
                                                                <div className="incrsDcrsInput">
                                                                    <a className="eventBtn dcrsBtn" title="dcrsBtn"></a>
                                                                    <input type="text" maxLength="3" className="incrsDecrsValue"  />
                                                                    <a className="eventBtn incrsBtn" title="incrsBtn"></a>
                                                                </div>
                                                                <div className="crtitem-prize-box"><span>€</span><input type="text" /></div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="crtitem-added-box">
                                                            <h5>Onion pizza</h5>
                                                            <div className="action-area">
                                                                <div className="incrsDcrsInput">
                                                                    <a className="eventBtn dcrsBtn" title="dcrsBtn"></a>
                                                                    <input type="text" maxLength="3" className="incrsDecrsValue" />
                                                                    <a className="eventBtn incrsBtn" title="incrsBtn"></a>
                                                                </div>
                                                                <div className="crtitem-prize-box"><span>€</span><input type="text" /></div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    
                                                </ul>

                                            </div>
                                        </div> */}

                                        {/*
                                        <div className="cartitem-subtotal-box">
                                            <div className="subtotal-row">
                                                <span>Subtotal</span>
                                                <span className="float-right">---</span>
                                            </div>
                                            <a href="#" className="btn btn-solid btn-block">CONTINUE</a>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </div> */}

                        </div>
                    </div>
                </section>
            <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        product_data: state.restaurant.get_response_product,
        add_to_cart: state.order.add_to_cart,
        loadingOrder: state.order.loading,
        cart_list_data: state.order.cart_list_data,
        loading: state.restaurant.loading,
        response: state.restaurant.response,
        error: state.restaurant.error, 
        loginDetails: state.login.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getRestaurantProductData: payload => dispatch(get_restaurant_product_data.request(payload)),
        addToCart: payload => dispatch(add_to_cart.request(payload)),
        cartList: payload => dispatch(cart_list.request(payload)),
        responseDismiss: () => dispatch(set_order_state({ add_to_cart: null })),
        responseDeleteDismiss: () => dispatch(set_order_state({ delete_cart_product_response: null })),
    }
  }

    const enhance = compose(
        withRouter,
        withNamespaces(),
        connect(
        mapStateToProps,
        mapDispatchToProps
        )
    );

export default enhance(Products);

  
  




