import api from "api";

import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types, register,
    post_user_login_data, set_login_state, post_user_logout_data, get_user_details, get_user_commission_list_data, remember_me, set_auth_state
} from "../reducers/login";
import {authorize_cart_data
} from "../reducers/order";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";
import {addLoginDetails, CheckForSession, removeSessionId, removeLoginDetails} from "../../Helper/Helper";
import history from "../../history"

function* registerCustomerData({payload}){
  const lang = localStorage.getItem("i18nextLng");

  try {
    const res = yield api.post("/register",payload, {
      headers: { "x-lang": lang }
    });
    
    yield put(register.success({ register: res.data }));
  } catch (err) {
    yield put(register.failure({ error: formatError(err) }));
  }
}

function* watchRegisterCustomerData(){
  yield takeLatest(types.REGISTER.REQUEST, registerCustomerData);
}

function* postUserLoginData({payload}){
  let lang = localStorage.getItem("i18nextLng");
  try {

    const res = yield api.post("/user-login",payload,{ "x-lang": lang }
    );

    yield put(post_user_login_data.success({ get_response_user_login_data: res.data }));
    const page = yield select(state => state.setting.page);

    if(res.data.responseStatus.STATUS == 'SUCCESS'){
      yield put(set_login_state(res.data.responseData)) // add login details to reducer
	  addLoginDetails(res.data.responseData); //add login details to local storage
      let session_id = CheckForSession();
      yield put(authorize_cart_data.request({session_id : session_id }));
      const page = yield select(state => state.setting.page);
	  yield put(set_auth_state({ remember_me_response: null }));
      removeSessionId();
      if(page){
        yield call(history.push, '/'+page)  
      }else{
        yield call(history.push, '/dashboard')
      }
    }



  } catch (err) {
    yield put(post_user_login_data.failure({ error: formatError(err) }));
  }
}

function* watchPostUserLoginData(){
  yield takeLatest(types.POST_USER_LOGIN_DATA.REQUEST, postUserLoginData);
}

function* postUserLogoutData({payload}){
  let lang = localStorage.getItem("i18nextLng");
  let authorization = ''
  let loggedDetails = yield select(state => state.login.login_details);
  
  if(loggedDetails){
    authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
  }
  try {

    const res = yield api.post("/user-logout",payload,{  
      headers: { "x-lang": lang , "x-access-token" : authorization }
    });

    yield put(post_user_logout_data.success({ post_response_user_logout_table: res.data }));
  } catch (err) {
    yield put(post_user_logout_data.failure({ error: formatError(err) }));
  }
}

function* watchPostUserLogoutData(){
  yield takeLatest(types.POST_USER_LOGOUT_DATA.REQUEST, postUserLogoutData);
}

function* getUserDetails({payload}){
  let lang = localStorage.getItem("i18nextLng");
  let authorization = ''
  let loggedDetails = yield select(state => state.login.login_details);
  
  if(loggedDetails){
    authorization = loggedDetails.remember_token ? loggedDetails.remember_token : '';
  }
  try {

    const res = yield api.post("/dashboard",payload, {
        headers: { "x-lang": lang , "x-access-token" : authorization }
      });

    yield put(get_user_details.success({ response_get_user_details: res.data }));
  } catch (err) {
    yield put(get_user_details.failure({ error: formatError(err) }));
  }
}

function* watchGetUserDetails(){
  yield takeLatest(types.GET_USER_DETAILS.REQUEST, getUserDetails);
}

function* getUserCommissionListData({payload}){
  let lang = localStorage.getItem("i18nextLng");
  try {
    console.log(lang);
    const res = yield api.post("/booked-list",payload,  
      {headers: { "x-lang": lang }}
    );

    yield put(get_user_commission_list_data.success({ response_get_user_commission_list_data: res.data }));
  } catch (err) {
    yield put(get_user_commission_list_data.failure({ error: formatError(err) }));
  }
}

function* watchGetUserCommissionListData(){
  yield takeLatest(types.GET_USER_COMMISSION_LIST_DATA.REQUEST, getUserCommissionListData);
}

function* rememberMeData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  
  try {
    const res = yield api.post("/rememberMeData",payload, {
      headers: { "x-lang": lang }
    });
    
    yield put(remember_me.success({ remember_me_response: res.data }));
  } catch (err) {
    yield put(remember_me.failure({ error: formatError(err) }));
  }
}

function* watchRememberMeData(){
  yield takeLatest(types.REMEMBER_ME.REQUEST, rememberMeData);
}

export default function* (){
    yield all([
        watchRegisterCustomerData(),
        watchPostUserLoginData(),
        watchPostUserLogoutData(),
        watchGetUserDetails(),
        watchGetUserCommissionListData(),
        watchRememberMeData()
    ]);
};