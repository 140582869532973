import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import LoginForm from './LoginForm/LoginForm';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SearchBanner from '../SearchBanner/SearchBanner';
import Modal from 'react-modal';
import PasswordRegen from './PasswordRegen/PasswordRegen';

const customStyles = {
    content : {
      top                   : '15%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      border                : '0',
      padding               :  '0',
      transform             : 'translate(-50%, 0%)'
    }
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang : props.lang,
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal(modal) {
        this.setState({[modal]: true});
    }
    
    closeModal(modal) {
        this.setState({[modal]: false});
    }

    render(){
        const { t, lang } = this.props;
        return (
            <>
            <Header />
            <SearchBanner />

                <div className="breadcrumb-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="breadcrumb-ul-list">
                                    <li><Link to="/"><i className="fa fa-home" aria-hidden="true"></i></Link></li>
                                    <li><span>{t('breadcrumb_login')}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <section className="section-block">
                    <div className="container">
                        <div className="row"> 
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                                <div className="shadow-box loginp_wrap">
                                    <div className="loginp_wrap_textco"><h2>Login</h2></div>
                                    <div className="loginp_innercont">    
                                    <LoginForm openModal={(val) => {
                                    this.openModal(val);
                                    }}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3"></div>
                        </div>  
                    </div>
                </section>


            <Footer />
            <Modal
            isOpen={this.state.forgotPasswordModal}
            onRequestClose={() => this.closeModal('forgotPasswordModal')}
            style={customStyles}
            contentLabel="Example Modal"
            ><div className="modal-content loginforg_wrapper">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle"><i className="fa fa-user-o" aria-hidden="true"></i> {t("password_regeneration")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={() => this.closeModal('forgotPasswordModal')}>
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <PasswordRegen onClose={() => {
                    this.closeModal('forgotPasswordModal');
                    }} />
            </div></Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
    }
  }

    const enhance = compose(
        withRouter,
        withNamespaces(),
        connect(
        mapStateToProps,
        mapDispatchToProps,
        
        )
    );

export default enhance (Login);
