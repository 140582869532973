import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { Trans } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import Slider from "react-slick";
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      />
    );
  }

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            searchData : ''
        }
        this.dataOnChange = this.dataOnChange.bind(this);
        this.searchSubmit = this.searchSubmit.bind(this);
    }

    dataOnChange(e){
        this.setState({ searchData: e.target.value });
    }

    searchSubmit(e){
        if(this.state.searchData != ""){
            this.props.history.push("/restaurant-list/"+this.state.searchData);
        }
    }

    render() { 

        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };


        const { t } = this.props;
        return ( 
            <section className="home-banner-area with-search">
                <img src="assets/images/home-banner-1.jpg" alt="" />
                <div className="home-banner-content-area banner-with-search">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-innr-content-wrap">
                                    <h1><small>{t("find_the_best")}</small>PARIS</h1>
                                    <div className="banner-search-area">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <input type="text" onChange={this.dataOnChange} placeholder={t("search_for_restaurants")} />
                                            </div>
                                            <div className="col-md-3">
                                                <input type="submit" onClick={this.searchSubmit} className="btn btn-solid btn-block" value={t("search_button")} />
                                            </div>
                                        </div>
                                    </div>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         );
    }
}
const enhance = compose(
	withRouter,
    withNamespaces()
);

export default enhance(Banner);
// export default withNamespaces()(Banner);