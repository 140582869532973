import i18n from 'i18next';
import { reactI18nextModule } from "react-i18next";
import translationEN from "./locales/en.json";
import translationFR from "./locales/fr.json";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
      translation: translationEN
    },
    fr: {
      translation: translationFR
    }
  };
i18n
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    resources,
    fallbackLng: 'fr',
    debug: true,
    lng: window.localStorage.getItem("i18nextLng") || "fr",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;