import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { Trans } from 'react-i18next';
import "./SearchBanner.css"
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    get_restaurant_data
} from "../../redux/reducers/restaurant";

class SearchBanner extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            searchData : "",
            searchDataVal : "",
            searchDataTxt : "",
            lang : props.lang,
        }
        this.dataOnChange = this.dataOnChange.bind(this);
        this.searchSubmit = this.searchSubmit.bind(this);
    }

    componentDidMount(){
        
        let search  = this.props.match.params.search
        if(typeof search !== 'undefined'){
            this.setState({ searchDataVal: search });
            this.props.getRestaurantData({searchData:search});
		}else{
            this.setState({ searchDataVal: '' });
            this.props.getRestaurantData({searchData:''});
        }
	}

    dataOnChange(e){
        this.setState({ searchDataVal: e.target.value });
    }

    searchSubmit(e){
        this.props.history.push("/restaurant-list/"+this.state.searchDataVal);
        // window.location.reload(false);
    }

    componentWillReceiveProps(){
        let url = window.location.href;
        let search = url.split('restaurant-list/')[1];
        if(search){
			search = decodeURIComponent(search)
            this.setState({
                searchDataVal: search
            },()=>{
                this.props.getRestaurantData({searchData:this.state.searchDataVal});
            })
        
        }else{
            this.setState({
                searchDataVal: ''
            },()=>{
                this.props.getRestaurantData({searchData:this.state.searchDataVal});
            })
        }
    }

    render() { 
        const { t, getRestaurantData, lang } = this.props;
        return ( 
            <section className="slick-banner-area inner-banner-image">
                <div className="home-banner-content-area">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-10">
                                <div className="row">
                                    <div className="banner-search-area">
                                        <div className="row">
                                            <div className="col-lg-10">&nbsp;</div>
                                            <div className="col-lg-10">
                                                <input type="text" name="search" value={this.state.searchDataVal} onChange={this.dataOnChange} placeholder={t("search_for_restaurants")} />
                                            </div>
                                            <div className="col-lg-2">
                                                <input type="submit" onClick={this.searchSubmit} className="btn btn-solid btn-block" value={t("search_button")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getRestaurantData: payload => dispatch(get_restaurant_data.request(payload)),
    }
  }

const enhance = compose(
	withRouter,
    withNamespaces(),
    connect(
        mapStateToProps,
        mapDispatchToProps
      )
);

export default enhance(SearchBanner);
// export default withNamespaces()(SearchBanner);