import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Ambassador from "./containers/Ambassador/Ambassador";
import Thankyou from "./containers/Thankyou/Thankyou";
import RestaurantList from "./containers/RestaurantList/RestaurantList";
import Home from "./containers/Home/Home";
import Login from "./containers/Login/Login";
import Products from "./containers/Products/Products";
import BookTable from "./containers/BookTable/BookTable";
import Dashboard from "./containers/Dashboard/Dashboard";
import Commission from "./containers/Commission/Commission";
import Checkout  from "./containers/Checkout/Checkout";
import Register  from "./containers/Login/Register";
import Aboutus from "./containers/Aboutus/Aboutus";
import ResetPassword from "./containers/ResetPassword/ResetPassword"
import Faq from "./containers/Faq/Faq"
import TermsService from "./containers/TermsService/TermsService"
import ContactsUs from "./containers/ContactsUs/ContactsUs"
import AmbassadorThankyou from "./containers/AmbassadorThankyou/AmbassadorThankyou";

/* const Router = () => {
    return (
        <Switch>
        	<Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/became-an-ambassador">
                <Ambassador />
            </Route>
            <Route exact path="/thank-you">
                <Thankyou />
            </Route>
            <Route exact path="/restaurant-list/:search?">
                <RestaurantList />
            </Route>
            <Route exact path="/products/:id">
                <Products />
            </Route>
            <Route exact path="/book-table/:id">
                <BookTable />
            </Route>
            <Route render={() => <Redirect to="/404" />} />
            
        </Switch>
    );
    
} */

const Router = ({ authorized }) => {
    return authorized ? (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
			<Route exact path="/became-an-ambassador">
                <Ambassador />
            </Route>
            <Route exact path="/dashboard">
                <Dashboard />
            </Route>
            <Route exact path="/commission/:id">
                <Commission />
            </Route>
            <Route exact path="/products/:id">
                <Products />
            </Route>
            <Route exact path="/checkout">
                <Checkout />
            </Route>
            <Route exact path="/thank-you">
                <Thankyou />
            </Route>
			<Route exact path="/ambassador-thank-you">
                <AmbassadorThankyou />
            </Route>
            <Route exact path="/restaurant-list/:search?">
                <RestaurantList />
            </Route>
            <Route exact path="/about-us">
                <Aboutus />
            </Route>
            <Route exact path="/faq">
                <Faq />
            </Route>
            <Route exact path="/terms-service">
                <TermsService />
            </Route>
            <Route exact path="/contact-us">
                <ContactsUs />
            </Route>
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    ) : (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
			<Route exact path="/became-an-ambassador">
                <Ambassador />
            </Route>
            <Route exact path="/register">
                <Register />
            </Route>
            <Route exact path="/products/:id">
                <Products />
            </Route>
            <Route exact path="/about-us">
                <Aboutus />
            </Route>
            <Route exact path="/reset-password/:token">
                <ResetPassword />
            </Route>
            <Route exact path="/restaurant-list/:search?">
                <RestaurantList />
            </Route>
            <Route exact path="/faq">
                <Faq />
            </Route>
            <Route exact path="/terms-service">
                <TermsService />
            </Route>
            <Route exact path="/contact-us">
                <ContactsUs />
            </Route>
            <Route render={() => <Redirect to="/404" />} />
        </Switch>
    );
    
}

export default Router;