import api from "api";

import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {types,
    post_ambassador_data
} from "../reducers/ambassador";
import { cleanPayload, formatError } from "./utils";
import i18next from "i18next";

function* postAmbassadorData({payload}){
  const lang = localStorage.getItem("i18nextLng");
  try {
    const res = yield api.post("/postAmbassadorData",payload.formData,  
      { "x-lang": lang, 'content-type': 'multipart/form-data'}
    );
    // yield api.post("/rest-auth/registration/", payload.params, {
    //   "Accept-Language": lang
    // });

    yield put(post_ambassador_data.success({ post_response_ambassador: res.data }));
  } catch (err) {
    yield put(post_ambassador_data.failure({ error: formatError(err) }));
  }
}

function* watchPostAmbassadorData(){
  yield takeLatest(types.POST_AMBASSADOR_DATA.REQUEST, postAmbassadorData);
}

export default function* (){
    yield all([
      watchPostAmbassadorData()
    ]);
};