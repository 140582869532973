import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
    REGISTER: createAsyncTypes("REGISTER"),
    AUTH_CHECK_USER_DATA: createAsyncTypes("AUTH_CHECK_USER_DATA"),
    AUTH_CHECK_USER_BALANCE: createAsyncTypes("AUTH_CHECK_USER_BALANCE"),
    GET_USER_DETAILS: createAsyncTypes("GET_USER_DETAILS"),
    GET_USER_COMMISSION_LIST_DATA: createAsyncTypes("GET_USER_COMMISSION_LIST_DATA"),
    POST_USER_LOGIN_DATA: createAsyncTypes("POST_USER_LOGIN_DATA"),
    POST_USER_LOGOUT_DATA: createAsyncTypes("POST_USER_LOGOUT_DATA"),
    REMEMBER_ME: createAsyncTypes("REMEMBER_ME"),
    LOGOUT: createAsyncTypes("LOGOUT"),
    SET_AUTH_STATE: "SET_AUTH_STATE",
    SET_LOGIN_STATE: "SET_LOGIN_STATE",
};

export const register = createAsyncActions("REGISTER");
export const auth_check_user_data = createAsyncActions("AUTH_CHECK_USER_DATA");
export const auth_check_user_balance = createAsyncActions("AUTH_CHECK_USER_BALANCE");
export const get_user_details = createAsyncActions("GET_USER_DETAILS");
export const get_user_commission_list_data = createAsyncActions("GET_USER_COMMISSION_LIST_DATA");
export const post_user_login_data = createAsyncActions("POST_USER_LOGIN_DATA");
export const post_user_logout_data = createAsyncActions("POST_USER_LOGOUT_DATA");
export const remember_me = createAsyncActions("REMEMBER_ME");
export const logout = createAsyncActions("LOGOUT");
export const set_auth_state = val => ({
  type: types.SET_AUTH_STATE,
  state: val
});
export const set_login_state = val => ({
  type: types.SET_LOGIN_STATE,
  state: val
});

const initialState = {
    register: null,
    response_auth_check_user_data: null,
    response_auth_check_user_balance: null,
    response_get_user_details: null,
    response_get_user_commission_list_data: null,
    get_response_user_login_data: null,
    get_response_user_logout_data: null,
    remember_me_response: null,
    logout_response: null,
    loading: null,
    response: null,
    error: null,
    login_details: null
};

const login = (state = initialState, action) => {
    switch (action.type) {  
      case types.SET_AUTH_STATE:
        return {
            ...state,
            ...action.state
        }; 
      case types.SET_LOGIN_STATE:
        return {
            ...state,
            login_details: action.state
        }; 
      case types.REGISTER.REQUEST:
        return {
          ...state,
          loadingRegister: action.type
        };
      case types.REGISTER.SUCCESS:
        return {
          ...state,
          register: action.payload.register,
          loadingRegister: null
        };
      case types.REGISTER.FAILURE:
        return {
          ...state,
          loadingRegister: null,
          error: action.payload.error
        };
      case types.AUTH_CHECK_USER_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.AUTH_CHECK_USER_DATA.SUCCESS:
        return {
          ...state,
          response_auth_check_user_data: action.payload.response_auth_check_user_data,
          loading: null
        };
      case types.AUTH_CHECK_USER_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      case types.POST_USER_LOGIN_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.POST_USER_LOGIN_DATA.SUCCESS:
        return {
          ...state,
          get_response_user_login_data: action.payload.get_response_user_login_data,
          loading: null
        };
      case types.POST_USER_LOGIN_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      case types.POST_USER_LOGOUT_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.POST_USER_LOGOUT_DATA.SUCCESS:
        return {
          ...state,
          get_response_user_logout_data: action.payload.get_response_user_logout_data,
          loading: null
        };
      case types.POST_USER_LOGOUT_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      case types.AUTH_CHECK_USER_BALANCE.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.AUTH_CHECK_USER_BALANCE.SUCCESS:
        return {
          ...state,
          response_auth_check_user_balance: action.payload.response_auth_check_user_balance,
          loading: null
        };
      case types.AUTH_CHECK_USER_BALANCE.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      case types.GET_USER_DETAILS.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_USER_DETAILS.SUCCESS:
        return {
          ...state,
          response_get_user_details: action.payload.response_get_user_details,
          loading: null
        };
      case types.GET_USER_DETAILS.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };

      case types.GET_USER_COMMISSION_LIST_DATA.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.GET_USER_COMMISSION_LIST_DATA.SUCCESS:
        return {
          ...state,
          response_get_user_commission_list_data: action.payload.response_get_user_commission_list_data,
          loading: null
        };
      case types.GET_USER_COMMISSION_LIST_DATA.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
        };
      case types.REMEMBER_ME.REQUEST:
        return {
          ...state,
          loading: action.type
        };
      case types.REMEMBER_ME.SUCCESS:
        return {
          ...state,
          remember_me_response: action.payload.remember_me_response,
          loading: null
        };
      case types.REMEMBER_ME.FAILURE:
        return {
          ...state,
          loading: null,
          error: action.payload.error
      };

      default:
            return state;
      }
};

export default login;