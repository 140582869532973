import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SearchBanner from '../SearchBanner/SearchBanner';
import ContactGoogleMap from '../ContactGoogleMap/ContactGoogleMap';
import ContactUsForm from './ContactUsForm/ContactUsForm';


class ContactUs extends Component {
    
    constructor(props) {
        super(props);
    }

    
    render() { 
        
        const { t } = this.props;
        return ( 
            <>
            <Header />
            <SearchBanner />
            <div className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="breadcrumb-ul-list">
                                <li><Link to="/"><i className="fa fa-home" aria-hidden="true"></i></Link></li>
                                <li><span>{t("contact_us")}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            
			<section className="section-block">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="shadow-box book-a-table-box">
								<div className="row">
									<div className="col-md-5">
										<div className="book-table-info-container">
											<h1 className="heading-h4">{t('contact_address')}</h1>
											<hr/>
											<div className="book-table-info">
												<p className="dark-gray">32, avenue Pierre 1er de Serbie, 75008 PARIS,France</p>
											</div>
											
											
											<ContactUsForm />
											
											
										</div>
									</div>

									<div className="col-md-7">
										<div className="shadow-box book-map-area contact-map">
											<ContactGoogleMap />
										</div>
									</div>
									
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>
			
			
			

            <Footer />
            </>
        );
    }
}



 
export default withNamespaces()(ContactUs);