import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { Provider } from 'react-redux'
import history from "./history"
import store from './redux/store'
import {
    Router
} from "react-router-dom";


const app = (
    <Provider store={store} >
      <Router history={history}>
        <App/>
      </Router>
    </Provider>
);
ReactDOM.render(app, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
