import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBanner from '../SearchBanner/SearchBanner';
import GoogleMap from '../GoogleMap/GoogleMap';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Slider from "react-slick";
import { getSessionOrAuthorizedToken, roundOffDecimal,dateFormat, addLoginDetails } from '../../Helper/Helper'
import { Scrollbars } from 'react-custom-scrollbars';
import Modal from 'react-modal';
import {
    get_customer_details
} from "../../redux/reducers/customer";
import {
    order_list
} from "../../redux/reducers/order";
import { get_user_details } from "../../redux/reducers/login";
import ShippingAddressForm from './ShippingAddressForm/ShippingAddressForm';
import BillingAddressForm from './BillingAddressForm/BillingAddressForm';
import ChangePassord from './ChangePassord/ChangePassord';
import EditProfile from './EditProfile/EditProfile';

const customStyles = {
  content : {
    top                   : '15%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    border                : '0',
    paddding              :  '0',
    transform             : 'translate(-50%, 0%)'
  }
};

const customStyles_address = {
    content : {
        marginTop             : '50px',
        left                  : '50%',
        right                 : 'auto',
        marginRight           : '-50%',
        border                :  '0',
        paddding              :  '0',
        transform             : 'translate(-50%, 0%)'


      
    }
  };


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            stateLoggedDetails : 'loading',
            changePasswordModal: false,
            editUserProfileModal : false,
            editShippingAddressModal : false,
            editBillingAddressModal : false,
			lang : props.lang,
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal(modal) {
        this.setState({[modal]: true});
    }
    
    closeModal(modal) {
        this.setState({[modal]: false});
    }
    getCustomerDetails = () => {
        const {loginDetails, customerDetails,getUserDetailsData } = this.props;
		let loggedDetails = addLoginDetails();
        let data = getSessionOrAuthorizedToken(loginDetails);
        customerDetails(data)
		getUserDetailsData({user_id:loggedDetails.user_id});
    }
    
    render() { 
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            
        };

        const { t, lang, customer_details_response, loginDetails, order_list_response, orderLists, get_user_details_data, getUserDetailsData } = this.props;
        if(this.state.stateLoggedDetails != loginDetails){
            this.setState({
                stateLoggedDetails : loginDetails
            },()=>{
                this.getCustomerDetails();
                let data = getSessionOrAuthorizedToken(loginDetails);
                orderLists(data)
            })
        }
		
		if(lang != this.state.lang){
        	this.setState({
				lang : lang,
				page : 0
        	},()=>{
				getUserDetailsData({user_id:loginDetails.user_id});
				let data = getSessionOrAuthorizedToken(loginDetails);
                orderLists(data)
        	});

        }
           
        // console.log(get_user_details_data);   

        // const [startDate, setStartDate] = useState(new Date());
        return ( 
            <>
            <Header />
            <SearchBanner />

	            <div className="dashboard">
			        <div className="container">
			            
			            <div className="row user-profile-row">
	                        <div className="col-lg-4">

	                        

	                            <div className="card">
	                                <div className="pro_wrapiing_content"><div className="loginp_wrap_textco"><h2>{t("profile_details")}</h2></div></div>
	                                <div className="card-body">
	                                    <div className="row">
	                                    	<div className="col-lg-12">
	                                            <div className="preview-info">
	                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") &&
	                                                <img src={customer_details_response.responseData.profile_image} alt="" />
	                                            	}
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-12">
	                                            <div className="preview-info">
	                                                <label><i className="preview-icon user"></i>
	                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.first_name+" "+customer_details_response.responseData.last_name : ""}
	                                                </label>
	                                            </div>
	                                        </div>
											<div className="col-lg-12">
	                                            <div className="preview-info">
	                                                <label><i className="preview-icon user"></i><a >
	                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.angeler_id : ""}
	                                                    </a></label>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-12">
	                                            <div className="preview-info">
	                                                <label><i className="preview-icon call"></i>
	                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.phone : ""}
	                                                </label>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-12">
	                                            <div className="preview-info">
	                                                <label><i className="preview-icon email"></i><a >
	                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.email : ""}
	                                                    </a></label>
	                                            </div>
	                                        </div>
										</div>
	                                    <div className="row">
	                                        <div className="col-lg-12">
												{/*
	                                            <a href="#" className="" data-toggle="modal" data-target="#change-password-modal" onClick={() => this.openModal('changePasswordModal')}><i className="fa fa-lock"></i> {t("change_password")}</a>
	                                            <a href="#" className="pull-right" data-toggle="modal" data-target="#edit-user-profile-modal" onClick={() => this.openModal('editUserProfileModal')}><i className="fa fa-pencil"></i> {t("edit_profile")}</a>
												*/}
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                        <div className="col-lg-4">
	                            <div className="card">
	                                <div className="pro_wrapiing_content"><div className="loginp_wrap_textco"><h2>{t("shipping_address")}</h2></div></div>
	                                <div className="card-body">
	                                    <div className="row">
	                                        <div className="col-lg-12">
	                                            <div className="address_content_list">
	                                                <label>{t("name")}</label>
	                                                <p>
	                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.shipping_first_name +" "+ customer_details_response.responseData.shipping_last_name : ""}
	                                                </p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-12">
	                                            <div className="address_content_list">
	                                                <label>{t("address")}</label>
	                                                <p>
	                                                {(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.shipping_address : ""}
	                                                </p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-4">
	                                            <div className="address_content_list">
	                                                <label>{t("postal_code")}</label>
	                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.shipping_postal_code : ""}</p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-4">
	                                            <div className="address_content_list">
	                                                <label>{t("country")}</label>
	                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.shipping_country : ""}</p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-4">
	                                            <div className="address_content_list">
	                                                <label>{t("city")}</label>
	                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.shipping_city : ""}</p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-12">
	                                            <div className="address_content_list">
	                                                <label>{t("phone")}</label>
	                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.shipping_phone : ""}</p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-12">
	                                            <a href="#" className="pull-right" data-toggle="modal" data-target="#edit-shipping-address-modal" onClick={() => this.openModal('editShippingAddressModal')}><i className="fa fa-pencil"></i> {t("edit")}</a>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                        <div className="col-lg-4">
	                            <div className="card">
	                                <div className="pro_wrapiing_content"><div className="loginp_wrap_textco"><h2>{t("billing_address")}</h2></div></div>
	                                <div className="card-body">
	                                    <div className="row">
	                                        <div className="col-lg-12">
	                                            <div className="address_content_list">
	                                                <label>{t("name")}</label>
	                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.billing_first_name +" "+ customer_details_response.responseData.billing_last_name : ""}</p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-12">
	                                            <div className="address_content_list">
	                                                <label>{t("address")}</label>
	                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.billing_address : ""}</p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-4">
	                                            <div className="address_content_list">
	                                                <label>{t("postal_code")}</label>
	                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.billing_postal_code : ""}</p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-4">
	                                            <div className="address_content_list">
	                                                <label>{t("country")}</label>
	                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.billing_country : ""}</p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-4">
	                                            <div className="address_content_list">
	                                                <label>{t("city")}</label>
	                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.billing_city : ""}</p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-12">
	                                            <div className="address_content_list">
	                                                <label>{t("phone")}</label>
	                                                <p>{(customer_details_response && customer_details_response.responseStatus.STATUS == "SUCCESS") ? 
	                                                customer_details_response.responseData.billing_phone : ""}</p>
	                                            </div>
	                                        </div>
	                                        <div className="col-lg-12">
	                                            <a href="#" className="pull-right" data-toggle="modal" data-target="#edit-billing-address-modal" onClick={() => this.openModal('editBillingAddressModal')}><i className="fa fa-pencil"></i> {t("edit")}</a>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                    </div>
			            
			            {order_list_response && order_list_response.responseStatus.STATUS == "SUCCESS" &&
			            <div className="row d-flex justify-content-center marginbottom-40">
			                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			                    <div className="border-box">
			                        <div className="form-section-head-tag marginbottom-30"><span className="blue-gradient-bg">{t('order_history')}</span></div>
			                        <div className="profile-details pt-0 pb-0  mt-0">
			                            <div className="profile-details-content p-0">
			                                <div className="table-responsive">
			                                    <Scrollbars style={{ height: 400 }}>
			                                        <table className="table mb-0 table-striped dashboard-front">
			                                            <thead>
			                                            <tr>
			                                                <th scope="col">{t("order_id")}</th>
															<th scope="col">{t("dashboard_book_rest_name")}</th>
			                                                <th scope="col">{t("order_date")}</th>
															<th scope="col">{t("order_status")}</th>
			                                                <th scope="col">{t("payment_type")}</th>
			                                                <th scope="col" style={{textAlign:"left"}}>{t("total_price")} (€)</th>
			                                                <th scope="col">{t("delivery_type")}</th>
			                                                <th scope="col">{t("delivery_date")}</th>
			                                            </tr>
			                                            </thead>
			                                      
			                                            <tbody>
			                                            	{order_list_response.responseData.map((val) => (
			                                                <tr key={val.code}>
			                                                    <td>{val.code}</td>
																<td>{val.restaurant}</td> 
		                                                        <td>{val.order_date}</td>
																<td>{val.order_status}</td>
		                                                        <td>{val.payment_type }</td>
		                                                        <td >{roundOffDecimal(val.total_price).toFixed(2)}</td>
		                                                        <td>{val.delivery_status}</td>
		                                                        <td>{val.delivery_date}</td>
			                                                </tr>
			                                            	))}
			                                            
			                                            
			                                            </tbody>
			                                        </table>
			                                    </Scrollbars>
			                                </div>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			            </div>
			            }
						
						
						
						
						
						<div className="row d-flex justify-content-center">
			                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
			                    <div className="border-box">
			                        <div className="form-section-head-tag marginbottom-30"><span className="blue-gradient-bg">{t('dashboard_ambassador')}</span></div>
			                        <div className="profile-details pt-0 pb-0 mt-0">
			                            <div className="profile-details-content p-0">
			                                <div className="table-responsive">
												<Scrollbars style={{ height: 400 }}>
													<table className="table mb-0 table-striped dashboard-front">
														<thead>
															<tr className="table-primary">
																<th rowSpan="2">{t('dashboard_amb_name')}</th>
																<th rowSpan="2">Email</th>
																<th rowSpan="2">{t('dashboard_amb_phone')}</th>
																<th colSpan="4" className="text-center">{t('dashboard_amb_restaunant')}</th>
																<th rowSpan="2">{t('dashboard_amb_status')}</th>
																<th rowSpan="2">{t('dashboard_amb_remarks')}</th>
																<th rowSpan="2" className="text-center">{t('dashboard_amb_bill')}</th>
																{/*<th rowSpan="2" className="text-center">Action</th>*/}
															</tr>
															<tr className="table-primary">
																<th>{t('dashboard_amb_name')}</th>
																<th className="address-col">{t('dashboard_amb_rest_address')}</th>
																<th>{t('dashboard_amb_rest_phone')}</th>
																<th>{t("zip_code")}</th>
															</tr>
														</thead>
														<tbody>
															{get_user_details_data && get_user_details_data.responseStatus.STATUS == "SUCCESS" && get_user_details_data.responseData.ambassadorData.map((ambVal) => (
															<tr key={ambVal.id}>
																<td>{ambVal.first_name} {ambVal.last_name}</td>
																<td>{ambVal.email}</td>
																<td>{ambVal.contact_no}</td>
																<td>{ambVal.restaurant_name}</td>
																<td>{ambVal.address} <br />{ambVal.city}, {ambVal.country}</td>
																<td>{ambVal.restaurent_contact_no}</td>
																<td>{ambVal.zipcode}</td>
																<td>{ambVal.status}</td>
																<td>{ambVal.remarks}</td>
																<td className="text-center">
																	{ambVal.image_name!="" && 
																		<a target="_blank" href={ambVal.image_name}><i className="fa fa-file-text" aria-hidden="true"></i></a>
																	}
																	{ambVal.image_name=="" && 
																		<span>--</span>
																	}
																</td>
																{/*
																<td>
																	{ambVal.link!="" && 
																		<Link to={ambVal.link}><i className="fa fa-eye" aria-hidden="true"></i></Link>
																	}
																	{ambVal.link=="" && 
																		<span>--</span>
																	}
																</td>
																*/}
															</tr>
															))}
															{get_user_details_data && (get_user_details_data.responseStatus.STATUS == "FAILED" && get_user_details_data.responseData.ambassadorData) &&
																<tr><td colSpan="9">{t("data_not_found")}</td></tr>
															}
														</tbody>
													</table>
												</Scrollbars>
			                                </div>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			            </div>
						
						
						
						
						
						
						
						
						
						
						
			        </div>
			    </div>
 
            <Footer />
            <Modal
            isOpen={this.state.changePasswordModal}
            onRequestClose={() => this.closeModal('changePasswordModal')}
            style={customStyles}
            contentLabel="Example Modal"
            ><div className="modal-content loginforg_wrapper">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle"><i className="fa fa-lock" aria-hidden="true"></i> {t("change_password")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={() => this.closeModal('changePasswordModal')}>
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <ChangePassord onClose={() => {
                    this.closeModal('changePasswordModal');
                    }} />
            </div></Modal>
            <Modal
            isOpen={this.state.editUserProfileModal}
            onRequestClose={() => this.closeModal('editUserProfileModal')}
            style={customStyles}
            contentLabel="Example Modal"
            >
 
 
                <div className="modal-content loginforg_wrapper">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle"><i className="fa fa-user-o" aria-hidden="true"></i> {t("edit_profile_details")}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={() => this.closeModal('editUserProfileModal')}>
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <EditProfile onClose={() => {
                    this.closeModal('editUserProfileModal');
                    this.getCustomerDetails();
                    }} customerDetail = {customer_details_response} />
                </div>
        
            </Modal>
            <Modal
            isOpen={this.state.editShippingAddressModal}
            onRequestClose={() => this.closeModal('editShippingAddressModal')}
            style={customStyles_address}
            contentLabel="Example Modal"
            ><div className="modal-content loginforg_wrapper">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle"><i className="icon-box location-pin"></i> {t("edit_shipping_address")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={() => this.closeModal('editShippingAddressModal')}>
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <ShippingAddressForm onClose={() => {
                    this.closeModal('editShippingAddressModal');
                    this.getCustomerDetails();
                    }} customerDetail = {customer_details_response} />
            </div></Modal>
            <Modal
            isOpen={this.state.editBillingAddressModal}
            onRequestClose={() => this.closeModal('editBillingAddressModal')}
            style={customStyles_address}
            contentLabel="Example Modal"
            ><div className="modal-content loginforg_wrapper">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle"><i className="icon-box location-pin"></i> {t("edit_billing_address")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeModal('editBillingAddressModal')}>
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
                <BillingAddressForm onClose={() => {
                    this.closeModal('editBillingAddressModal');
                    this.getCustomerDetails();
                    }} customerDetail = {customer_details_response} />
            </div></Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        customer_details_response: state.customer.customer_details_response,
        order_list_response: state.order.order_list_response,
		get_user_details_data: state.login.response_get_user_details,
        loading: state.restaurant.loading,
        response: state.restaurant.response,
        error: state.restaurant.error, 
        loginDetails: state.login.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        customerDetails: payload => dispatch(get_customer_details.request(payload)),
        orderLists: payload => dispatch(order_list.request(payload)),
		getUserDetailsData: payload => dispatch(get_user_details.request(payload)),
    }
  }

    const enhance = compose(
        withRouter,
        withNamespaces(),
        connect(
        mapStateToProps,
        mapDispatchToProps,
        
        )
    );

export default enhance (Dashboard);

  
  




