import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SearchBanner from '../SearchBanner/SearchBanner';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {
    get_restaurant_data
} from "../../redux/reducers/restaurant";
import {
    get_featured_restaurant_data
} from "../../redux/reducers/featured_restaurant_list";
import { page } from "../../redux/reducers/setting";



class RestaurantList extends Component {

	constructor(props) {
        super(props);
        this.state = { 
            data : [],
			lang : props.lang,
			restaurantLimit : "",
			searchDataVal : "",
			search : "",
			page : 0,
			isOpen : false,
			photoIndex: 0,
		}
		this.pageListing = this.pageListing.bind(this)
        this.changePage = this.changePage.bind(this)
	}
	
	changePage(page){
        if(page == 'left'){
            let pg = this.state.page - 1;
            this.setState({
                page : pg
            },()=>{
                this.props.getRestaurantData({searchData: this.state.searchDataVal,page:this.state.page})  
            })
        }else if(page == 'right'){
            let pg = this.state.page + 1;
            this.setState({
                page : pg
            },()=>{
                this.props.getRestaurantData({searchData: this.state.searchDataVal,page:this.state.page})  
            })
        }else{
            this.setState({
                page : page
            })
            this.props.getRestaurantData({searchData: this.state.searchDataVal,page:page})
        }
        window.scrollTo(0, 0)
    }

    pageListing(total,limit){
        let offset = Math.ceil(parseInt(total)/parseInt(limit))
        let html = ''
        if(offset && offset>1){
            html = []
            if(this.state.page != 0){
                html.push(<li key="left" className="page-item" onClick={() => this.changePage('left')}><span className="page-link"><i className="fa fa-arrow-left" aria-hidden="true"></i></span></li>)
            }else{
                html.push(<li key="left" className="page-item disabled"  >
                        <span className="page-link" style={{backgroundColor: "white",
                    borderColor: "white"}}>
                            <i className="fa fa-arrow-left" style={{color: "grey"}} aria-hidden="true"></i></span></li>)
            }
            let cnt = 0;
            for ( let j= 0; j < offset; j++) {
                cnt++;
                let active = this.state.page == j ? 'active' : '';
                html.push(<li key={j} onClick={() => this.changePage(j)} className={`page-item ${active}`}>
                    <a className="page-link" >{j + 1}</a></li>)
            }
            if(this.state.page != cnt-1){
                html.push(<li key="right" className="page-item" onClick={() => this.changePage('right')}><a className="page-link" ><i className="fa fa-arrow-right" aria-hidden="true"></i></a></li>)
            }else{
                html.push(<li key="right" className="page-item disabled" ><a className="page-link" style={{backgroundColor: "white",
                borderColor: "white"}}><i className="fa fa-arrow-right" style={{color: "grey"}} aria-hidden="true"></i></a></li>)
            }
            
                                    
        }
        
        return html;
	}
	
    componentDidMount(){
		const { getRestaurantData, getFeaturedRestaurantData} = this.props;
		const { search } = this.props.match.params
		if(typeof search !== 'undefined'){
			this.setState({ searchDataVal: search });
		}
		// getRestaurantData({searchData:(typeof search !== 'undefined')?search:""});
        getFeaturedRestaurantData({restaurantLimit:this.state.restaurantLimit});

	}

	componentWillReceiveProps(){
        let url = window.location.href;
		let search = url.split('restaurant-list/')[1];
		if(this.state.searchDataVal != search){
            this.setState({
                searchDataVal : search,
                page : 0,
            })
        }else{
            this.setState({
                // page : 0,
            })
        }
	}
	
	checkBookUserStatus = (fid)=>{
        const { page , loginDetails, history } = this.props
        if(loginDetails){
            history.push('/book-table/'+fid);
        }else{
            page("book-table/"+fid);
            history.push(`/login`);
        }
    }
	
    render() { 
		const { t, restaurant_data, featured_restaurant_data, lang, getRestaurantData, getFeaturedRestaurantData } = this.props;
		const { photoIndex } = this.state;
        if(lang != this.state.lang){
        	this.setState({
				lang : lang,
				page : 0
        	},()=>{
        		getFeaturedRestaurantData({restaurantLimit:this.state.restaurantLimit});
        	});

		}
		
		return ( 
            <>
            <Header />
            <SearchBanner />

	            <div className="breadcrumb-section">
	                <div className="container">
	                    <div className="row">
	                        <div className="col-lg-12">
	                            <ul className="breadcrumb-ul-list">
	                                <li><Link to="/"><i className="fa fa-home" aria-hidden="true"></i></Link></li>
	                                <li><span>{t("restaurant_list")}</span></li>
	                            </ul>
	                        </div>
	                    </div>
	                </div>
	            </div>
	            
	            <section className="section-block marginbottom-0">
	                <div className="container">
	                    <div className="row">
	                        <div className="col-lg-12">
	                            <h4 className="section-heading mid"><strong>{t("book_a_restaurant")}</strong></h4>
	                        </div>
	                    </div>
	                </div>
	            </section>
	            
	            <section className="section-block">
	                <div className="container">
	                    <div className="row">
	                        <div className="col-lg-8">
	                            <ul className="rstuarnt-add-list">
									{restaurant_data && restaurant_data.responseStatus.STATUS == "SUCCESS" && restaurant_data.responseData.data.map((val) => (
										<li key={val.id}>
									        <div className="rstuarnt-row-viewbox">
									            <div className="rstuarnt-img-frame"><img src={val.restaurant_image} alt="" /></div>
									            <div className="rstuarnt-info-area">
									                <div className="rstuarnt-info-col">
									                	{val.ambassadorImg != "" &&
									                		<>
										                    <div className="ambassador-profile-pic-holder">
										                        <div className="ambassador-profile-pic"><img src={val.ambassadorImg} alt="" /></div>
										                        <div className="ambassador-hat"><img src={window.location.origin+"/assets/images/ambassado-icon.svg"} alt="" />
										                        </div>
										                    </div>
										                    </>
									                	}
									                    <h3><a>{val.name}</a></h3>
									                    <p>{val.address}</p>
									                    <p>
									                    {val.cuisines &&
									                    	<>
									                     	{t("cuisine")}: {val.cuisines} <br/>
									                     	</>
									                 	}
									                    {val.schedule &&	
									                    	<>
									                    	{t("hours")}: {val.schedule} 
									                    	</>
									                	}
									                    </p>
									                    <div className="chef-profile small">
									                    	{val.chef_name && 
									                        <div className="chef-profile-pic-holder">
									                            <div className="chef-profile-pic"><img src={val.chef_image} alt="" /></div>
									                        </div>
									                    	}
									                        {val.chef_name && <div className="chef-name">{val.chef_name}</div> }
									                    </div>
									                </div>
									            </div>
									        </div>
									        <div className="gray-actn-btn-area">
									            <ul>
									                <li>{/*<a style={{cursor:"pointer"}} onClick={() => this.checkBookUserStatus(val.id)} disabled={val.is_book=='N'} ><img src="assets/images/dinner-table-icon.png" alt="" /> {t("book_a_table")}</a>*/}</li>
									                <li><Link to={`/products/${val.id}`} disabled={val.is_order=='N'}><img src="assets/images/order-now-icon.png" alt="" /> {t("order_online")}</Link></li>
									                <li>
														{/*
														<a style={{cursor: "pointer"}} onClick={() => this.setState({ [val.id]: true })} disabled={val.is_menu=='N'}><img src="assets/images/view-menu-icon.png" alt="" /> {t("view_menu")}</a>*/}
								{console.log(val.restaurant_menu)}
									{val.restaurant_menu.length > photoIndex && this.state[val.id] && <Lightbox
                                        mainSrc={val.restaurant_menu[photoIndex]}
                                        nextSrc={val.restaurant_menu[(photoIndex + 1) % val.restaurant_menu.length]}
                                        prevSrc={val.restaurant_menu[(photoIndex + val.restaurant_menu.length - 1) % val.restaurant_menu.length]}
                                        onCloseRequest={() => this.setState({ [val.id]: false })}
                                        onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + val.restaurant_menu.length - 1) % val.restaurant_menu.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % val.restaurant_menu.length,
                                        })
                                        }
                                    />}





													</li>
									            </ul>
									        </div>
									    </li>
								    ))}

								    {restaurant_data && restaurant_data.responseStatus.STATUS == "FAILED" &&
								        <div className="col-md-12" ><h4>{t("restaurant_not_found")}</h4></div>
								    }
								</ul>

								{restaurant_data && restaurant_data.responseStatus.STATUS == "SUCCESS" && 
	                            <section className="section-block marginbottom-0">
	                                <div className="row">
	                                    <div className="col-lg-12">
	                                        <div className="pagination-section">
	                                            {/* <div className="page-count">Page 1 of 539</div> */}
	                                            <div className="pagination-area">
	                                                <nav aria-label="navigation">
	                                                    <ul className="pagination">
														{this.pageListing(
														restaurant_data.responseData.total_restaurant,
														restaurant_data.responseData.limit)}
	                                                    </ul>
	                                                </nav>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </section>}




	                        </div>

	                        <div className="col-lg-4">
	                            <div className="shadow-box">
	                                <div className="graybg-box graybg-box-heading">
	                                    <h4 className="mid text-center"><div dangerouslySetInnerHTML={{ __html: t("featured_restaurants") }} /></h4>
	                                </div>
	                                <div className="featurd-rstrnt-list-area">
	                                    <ul className="featurd-rstrnt-list">
											{featured_restaurant_data && featured_restaurant_data.responseStatus.STATUS == "SUCCESS" && featured_restaurant_data.responseData.map((featuredVal) => (
											    <li key={featuredVal.id}>
											    	<div className="rstuarnt-row-viewbox small">
											            <div className="rstuarnt-img-frame"><img src={featuredVal.restaurant_image} alt="{featuredVal.name}" /></div>
											            <div className="rstuarnt-info-area">
											                <div className="rstuarnt-info-col">
											                    <h3><a>{featuredVal.name}</a></h3>
											                    <p className="elips">{t("cuisine")}: {featuredVal.cuisines}</p>
											                    <p className="elips">{t("hours")}: {featuredVal.schedule}</p>
											                </div>
											            </div>
											        </div>
											    </li>
											))}

											{featured_restaurant_data && featured_restaurant_data.responseStatus.STATUS == "FAILED" &&
											    <div className="col-md-12" ><h4>{t("restaurant_not_found")}</h4></div>
											}
										</ul>
	                                </div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </section>
	        
	        <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.setting.lng,
        restaurant_data: state.restaurant.get_response_restaurant,
        featured_restaurant_data: state.featuredRestaurant.get_response_featured_restaurant,
        loading: state.restaurant.loading,
        response: state.restaurant.response,
		error: state.restaurant.error,
		loginDetails: state.login.login_details,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getRestaurantData: payload => dispatch(get_restaurant_data.request(payload)),
		getFeaturedRestaurantData: payload => dispatch(get_featured_restaurant_data.request(payload)),
		page: payload => dispatch(page(payload)),
    }
  }

const enhance = compose(
	withRouter,
    withNamespaces(),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
);

export default enhance(RestaurantList);