import { createAsyncActions, createAsyncTypes } from "./utils";

export const types = {
    ADD_TO_CART: createAsyncTypes("ADD_TO_CART"),
    SET_ORDER_STATE: "SET_ORDER_STATE",
    CART_LIST: createAsyncTypes("CART_LIST"),
    DELETE_CART_PRODUCT: createAsyncTypes("DELETE_CART_PRODUCT"),
    AUTHORIZE_CART_DATA: createAsyncTypes("AUTHORIZE_CART_DATA"),
    CHECKOUT: createAsyncTypes("CHECKOUT"),
    ORDER_LIST: createAsyncTypes("ORDER_LIST"),
}

export const add_to_cart = createAsyncActions("ADD_TO_CART");
export const cart_list = createAsyncActions("CART_LIST");
export const delete_cart_product = createAsyncActions("DELETE_CART_PRODUCT");
export const authorize_cart_data = createAsyncActions("AUTHORIZE_CART_DATA");
export const checkout = createAsyncActions("CHECKOUT");
export const order_list = createAsyncActions("ORDER_LIST");
export const set_order_state = val => ({
    type: types.SET_ORDER_STATE,
    state: val
});

const initialState = {
    delete_cart_product_response: null,
    cart_list_data: null,
    add_to_cart: null,
    authorize_cart_response: null,
    checkout_response: null,
    order_list_response: null,
    loading: null,
    response: null,
    error: null,
    loadingDeleteProduct : null,
    loadingCheckout : null
};

const order = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ORDER_STATE:
            return {
                ...state,
                ...action.state
            }; 
        case types.ADD_TO_CART.REQUEST:
            return {
                ...state,
                loading: action.type
            };
        case types.ADD_TO_CART.SUCCESS:
            return {
                ...state,
                add_to_cart: action.payload.add_to_cart,
                loading: null
            };
        case types.ADD_TO_CART.FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload.error
            };
        case types.CART_LIST.REQUEST:
            return {
                ...state,
                loading: action.type
            };
        case types.CART_LIST.SUCCESS:
            return {
                ...state,
                cart_list_data: action.payload.cart_list_data,
                loading: null
            };
        case types.CART_LIST.FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload.error
            };
        case types.DELETE_CART_PRODUCT.REQUEST:
            return {
                ...state,
                loadingDeleteProduct: action.type
            };
        case types.DELETE_CART_PRODUCT.SUCCESS:
            return {
                ...state,
                delete_cart_product_response: action.payload.delete_cart_product_response,
                loadingDeleteProduct: null
            };
        case types.DELETE_CART_PRODUCT.FAILURE:
            return {
                ...state,
                loadingDeleteProduct: null,
                error: action.payload.error
            };
        case types.AUTHORIZE_CART_DATA.REQUEST:
            return {
                ...state,
                loading: action.type
            };
        case types.AUTHORIZE_CART_DATA.SUCCESS:
            return {
                ...state,
                authorize_cart_response: action.payload.authorize_cart_response,
                loading: null
            };
        case types.AUTHORIZE_CART_DATA.FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload.error
            };
        case types.CHECKOUT.REQUEST:
            return {
                ...state,
                loadingCheckout: action.type
            };
        case types.CHECKOUT.SUCCESS:
            return {
                ...state,
                checkout_response: action.payload.checkout_response,
                loadingCheckout: null
            };
        case types.CHECKOUT.FAILURE:
            return {
                ...state,
                loadingCheckout: null,
                error: action.payload.error
            };
        case types.ORDER_LIST.REQUEST:
            return {
                ...state,
                loading: action.type
            };
        case types.ORDER_LIST.SUCCESS:
            return {
                ...state,
                order_list_response: action.payload.order_list_response,
                loading: null
            };
        case types.ORDER_LIST.FAILURE:
            return {
                ...state,
                loading: null,
                error: action.payload.error
            };
        default:
            return state;
    }
};

export default order